<template>
  <v-input v-model="matrix">
    <v-data-table
        :headers="headers"
        :items="matrix"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
        dense
        class="w-100"
    >
      <template v-slot:item.type="{item}">
        <span v-if="columnTypes.find(i => i.type === item.type)">{{
            columnTypes.find(i => i.type === item.type).label
          }}</span>
      </template>
      <template v-slot:item.percent="{item}">
        <v-icon small v-if="item.percent">{{ icons.mdiCheck }}</v-icon>
      </template>
      <template v-slot:item.required="{item}">
        <v-icon small v-if="item.required">{{ icons.mdiCheck }}</v-icon>
      </template>
      <template v-slot:item.options="{item}">
        <v-icon small v-if="Array.isArray(item.options)">{{ item.options.join(', ') }}</v-icon>
      </template>
      <template v-slot:footer>
        <v-toolbar flat dense color="white">
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{on}">
              <v-btn fab x-small color="info" dark v-on="on">
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-select v-model.trim="editedItem.type" :items="columnTypes" item-text="label" item-value="type"
                              hide-details="auto" class="mb-4"
                              :label="$t('type')"></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model.trim="editedItem.prop" hide-details="auto" class="mb-4"
                                  :label="$t('prop_variable')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model.trim="editedItem.label" hide-details="auto" class="mb-4"
                                  :label="$t('label')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model.trim="editedItem.hint" hide-details="auto" class="mb-4"
                                  :label="$t('hint')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model.trim="editedItem.headerLabel" hide-details="auto" class="mb-4"
                                  :label="$t('list_label')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                        v-model="editedItem.qualifier"
                        :items="qualifiers"
                        item-text="text"
                        item-value="prop"
                        hide-details="auto"
                        class="mb-4"
                        :label="$t('qualifier')"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="editedItem.qualifier && !['yesno', 'checkbox'].includes(selectedQualifier.type)">
                    <v-text-field v-model.trim="editedItem.qualifierValue" hide-details="auto" class="mb-4"
                                  :label="$t('qualifier_value')"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-checkbox v-model="editedItem.percent" hide-details="auto" class="mb-4" v-if="editedItem.type === 'number'"
                                :label="$t('percent')"></v-checkbox>
                    <v-checkbox v-model="editedItem.row" hide-details="auto" class="mb-4" v-if="editedItem.type === 'yesno'"
                                :label="$t('vertical_radio_buttons')"></v-checkbox>
                    <v-checkbox v-model="editedItem.multiple" hide-details="auto" class="mb-4" v-if="['country', 'select'].includes(editedItem.type)"
                                :label="$t('multiselect')"></v-checkbox>
                    <v-textarea v-model.trim="editedItem.options" hide-details="auto" class="mb-4" v-if="editedItem.type === 'select'"
                                :label="$t('options')"></v-textarea>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-checkbox v-model="editedItem.required" hide-details="auto" class="mb-4"
                                :label="$t('required')"></v-checkbox>
                    <v-checkbox v-model="editedItem.prependLabel" hide-details="auto" class="mb-4"
                                :label="$t('label_above_field')"></v-checkbox>
                    <v-checkbox v-model="editedItem.header" hide-details="auto" class="mb-4"
                                :label="$t('show_in_list')"></v-checkbox>
                    <v-checkbox v-model="editedItem.fullWidth" hide-details="auto" class="mb-4"
                                :label="$t('full_width')"></v-checkbox>
                    <v-checkbox v-model="editedItem.breakAfter" hide-details="auto" class="mb-4"
                                :label="$t('break_after')"></v-checkbox>
                    <v-checkbox v-model="editedItem.dividerAfter" hide-details="auto" class="mb-4"
                                :label="$t('divider_after')"></v-checkbox>
                    <v-checkbox v-model="editedItem.dividerBefore" hide-details="auto" class="mb-4"
                                :label="$t('divider_before')"></v-checkbox>
                    <v-checkbox v-model="editedItem.dense" hide-details="auto" class="mb-4"
                                :label="$t('compact')"></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="secondary" text @click="close">{{ $t('button_cancel') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="info" text @click="save">{{ $t('button_save') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <span class="text-no-wrap">
            <v-btn icon x-small :disabled="Boolean(getIndex(item) === 0)" class="mr-2"
                   @click="move(true, item)">
                <v-icon small>
                    {{ icons.mdiChevronUp }}
                </v-icon>
            </v-btn>
            <v-btn icon x-small :disabled="(getIndex(item) === matrix.length - 1)" class="mr-2"
                   @click="move(false, item)">
                <v-icon small>
                    {{ icons.mdiChevronDown }}
                </v-icon>
            </v-btn>
            <v-btn icon x-small class="mr-2" @click="editItem(item)">
                <v-icon small>
                    {{ icons.mdiPencil }}
                </v-icon>
            </v-btn>
            <v-btn icon x-small @click="deleteItem(item)">
                <v-icon small>
                    {{ icons.mdiDelete }}
                </v-icon>
            </v-btn>
        </span>
      </template>
    </v-data-table>
  </v-input>
</template>

<script>
import {mdiCheck, mdiChevronDown, mdiChevronUp, mdiCircle, mdiDelete, mdiPencil, mdiPlus} from '@mdi/js';
import Short from '../shortuid';
import _cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'ColumnTable',
  props: {
    question: Object,
  },
  data: () => ({
    dialog: false,
    search: null,
    matrix: [],
    editedIndex: -1,
    editedItem: {
      type: '',
      label: '',
      hint: '',
      options: [],
      dense: false,
      fullWidth: false,
    },
    defaultItem: {
      id: '',
      option: '',
      rating: 0,
      requireSubs: false,
      singleChoice: false,
      answerTags: [],
    },
    requiredRule: [
      v => !!v || 'Required',
    ],
    icons: {
      mdiPencil,
      mdiDelete,
      mdiPlus,
      mdiCheck,
      mdiCircle,
      mdiChevronUp,
      mdiChevronDown,
    }
  }),
  computed: {
    headers() {
      return [
        {text: this.$t('type'), value: 'type'},
        {text: this.$t('label'), value: 'label'},
        {text: this.$t('prop_variable'), value: 'prop'},
        {text: this.$t('percent'), value: 'percent', align: 'center'},
        {text: this.$t('required'), value: 'required', align: 'center'},
        {text: this.$t('options'), value: 'options'},
        {text: '', value: 'actions'},
      ];
    },
    qualifiers() {
      const items = this.matrix.map(i => ({label: i.label, prop: i.prop, type: i.type, text: `${i.label} (${i.prop})`}));
      if (this.editedItem.prop) {
        const editedIndex = items.findIndex(i => i.prop === this.editedItem.prop);
        items.splice(editedIndex, 1);
      }
      items.unshift({label: '', prop: '', text: ''});
      return items;
    },
    selectedQualifier() {
      return this.editedItem.qualifier ? this.matrix.find(i => i.prop === this.editedItem.qualifier) ?? {} : {};
    },
    columnTypes() {
      return [
        {label: this.$t('columnTypes.text'), type: 'text'},
        {label: this.$t('columnTypes.number'), type: 'number'},
        {label: this.$t('columnTypes.select'), type: 'select'},
        {label: this.$t('columnTypes.yesno'), type: 'yesno'},
        {label: this.$t('columnTypes.checkbox'), type: 'checkbox'},
        {label: this.$t('columnTypes.file'), type: 'file'},
        {label: this.$t('columnTypes.country'), type: 'country'},
        {label: this.$t('columnTypes.mapmarker'), type: 'mapmarker'},
      ];
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('add_column') : this.$t('edit_column');
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.matrix = [];
      if (this.question.columns && this.question.columns.length > 0) {
        this.matrix.push(...this.question.columns);
        for (const item of this.matrix) {
          item.header ??= true;
        }
      }
    },
    getIndex(item) {
      let index = -1;
      for (let idx = 0; idx < this.matrix.length; idx += 1) {
        if (item.id === this.matrix[idx].id) {
          index = idx;
        }
      }
      return index;
    },
    move(up, item) {
      let from = this.getIndex(item);
      let to = (up) ? from - 1 : from + 1;
      if (from > -1) {
        this.matrix.splice(to, 0, this.matrix.splice(from, 1)[0]);
      }
      this.storeColumns();
    },
    editItem(item) {
      this.editedIndex = this.matrix.indexOf(item);
      this.editedItem = _cloneDeep(item);
      this.editedItem.header ??= true;
      if (Array.isArray(this.editedItem.options)) {
        this.editedItem.options = this.editedItem.options.join('; ');
      }
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.matrix.indexOf(item);
      confirm(this.$t('confirm_delete_row')) && this.matrix.splice(index, 1);
      this.storeColumns();
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (!this.editedItem.id) {
        this.editedItem.id = Short.generate();
      }
      if (this.editedItem.type === 'select' && this.editedItem.options) {
        this.editedItem.options = this.editedItem.options.split(';').map(i => i.trim());
      } else {
        delete this.editedItem.options;
      }
      if (this.editedIndex > -1) {
        Object.assign(this.matrix[this.editedIndex], this.editedItem);
      } else {
        this.matrix.push(this.editedItem);
      }
      this.storeColumns();
      this.close();
    },
    storeColumns() {
      this.question.columns = _cloneDeep(this.matrix);
      this.$emit('changed');
    }
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.v-chip-group .v-chip {
  margin: 0 8px 0 0;
}
</style>
