<template>
  <v-input v-model="matrix">
    <v-data-table
        :headers="headers"
        :items="matrix"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
        dense
        class="w-100">
      <template v-slot:item.countries="{item}">
        {{
          item.countries.length > 0 ? item.countries.map(i => countries.find(c => c.alpha3Code === i).name).join(', ') : ''
        }}
      </template>
      <template v-slot:item.preset="{item}">
        {{ item.preset ? presets.find(p => p.preset === item.preset).label : '' }}
      </template>
      <template v-slot:item.rating="{item}">
        <div class="text-no-wrap">
          <v-icon x-small :color="item.rating > 0 || item.rating === 0 ? ratingColors[item.rating] : 'rgba(0, 0, 0, .2)'">{{ icons.mdiCircle }}</v-icon>
          {{ ratingLabel(item) }}
        </div>
      </template>
      <template v-slot:item.requireSubs="{item}">
        <v-icon small v-if="item.requireSubs">{{ icons.mdiCheck }}</v-icon>
      </template>
      <template v-slot:item.answerTags="{item}">
        <v-chip-group column>
          <v-chip x-small v-for="tag in item.answerTags" :key="tag" class="my-0">{{ tag }}</v-chip>
        </v-chip-group>
      </template>
      <template v-slot:footer>
        <v-toolbar flat dense color="white">
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{on}">
              <v-btn fab x-small color="info" dark v-on="on">
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <v-select
                          multiple
                          v-model.trim="editedItem.countries"
                          :items="countries"
                          item-text="name"
                          item-value="alpha3Code"
                          :label="$t('countries')"></v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                          v-model.trim="editedItem.preset"
                          :items="presets"
                          item-text="label"
                          item-value="preset"
                          :label="$t('preset')"></v-select>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <v-select v-model="editedItem.rating" :items="ratings" item-text="label"
                                item-value="value"
                                :label="$t('rating')">
                        <template slot="append">
                          <v-icon :color="editedItem.rating > 0 || editedItem.rating === 0 ? ratingColors[editedItem.rating] : 'rgba(0, 0, 0, .2)'">
                            {{ icons.mdiCircle }}
                          </v-icon>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col v-if="!isSub" cols="12" sm="6">
                      <v-switch v-model="editedItem.requireSubs"
                                color="info"
                                :label="$t('require_subs')"></v-switch>
                    </v-col>
                    <v-col cols="6">
                      <v-combobox
                          v-model="editedItem.answerTags"
                          :items="tagPool"
                          :search-input.sync="search"
                          hide-selected
                          :label="$t('answer_tags')"
                          multiple
                          persistent-hint
                          deletable-chips
                          small-chips>
                      </v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray" text @click="close">{{ $t('button_cancel') }}</v-btn>
                <v-btn color="info" text @click="save">{{ $t('button_save') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-no-wrap">
          <v-btn icon x-small :disabled="Boolean(getIndex(item) === 0)" class="mr-2"
                 @click="move(true, item)">
            <v-icon small>
              {{ icons.mdiChevronUp }}
            </v-icon>
          </v-btn>
          <v-btn icon x-small :disabled="(getIndex(item) === matrix.length - 1)" class="mr-2"
                 @click="move(false, item)">
            <v-icon small>
              {{ icons.mdiChevronDown }}
            </v-icon>
          </v-btn>
          <v-btn icon x-small class="mr-2" @click="editItem(item)">
            <v-icon small>
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
          <v-btn icon x-small @click="deleteItem(item)">
            <v-icon small>
              {{ icons.mdiDelete }}
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-input>
</template>

<script>
import {mdiPencil, mdiDelete, mdiPlus, mdiCheck, mdiCircle, mdiChevronUp, mdiChevronDown} from '@mdi/js';
import Short from '../shortuid';
import Countries from '../data/Countries.json';

export default {
  name: 'CountryGroupTable',
  props: {
    question: Object,
    tagPool: Array,
    isSub: Boolean,
  },
  data: () => ({
    countries: Countries,
    dialog: false,
    search: null,
    matrix: [],
    presets: [
      {preset: '', label: 'None'},
      {preset: 'gac_60_80', label: 'Evaluate GAC 60 – 80'},
      {preset: 'gac_70_80', label: 'Evaluate GAC 70 – 80'},
      {preset: 'gac_lt_60', label: 'Evaluate GAC < 60'},
      {preset: 'gac_lt_70', label: 'Evaluate GAC < 70'},
      {preset: 'gac_lt_80', label: 'Evaluate GAC < 80'},
      {preset: 'gac_gt_80', label: 'Evaluate GAC > 80'},
      {preset: 'wide_60_80', label: 'Evaluate Wide 60 – 80'},
      {preset: 'wide_70_80', label: 'Evaluate Wide 70 – 80'},
      {preset: 'wide_lt_60', label: 'Evaluate Wide < 60'},
      {preset: 'wide_lt_70', label: 'Evaluate Wide < 70'},
      {preset: 'wide_lt_80', label: 'Evaluate Wide < 80'},
      {preset: 'wide_gt_80', label: 'Evaluate Wide > 80'},
      {preset: 'cahra', label: 'CAHRA'},
      {preset: 'non_cahra', label: 'Non-CAHRA'},
    ],
    editedIndex: -1,
    editedItem: {
      id: '',
      countries: [],
      preset: '',
      rating: 0,
      requireSubs: false,
      answerTags: [],
    },
    defaultItem: {
      id: '',
      countries: [],
      preset: '',
      rating: 0,
      requireSubs: false,
      answerTags: [],
    },
    requiredRule: [
      v => !!v || 'Required',
    ],
    ratings: [
      {
        label: 'No rating',
        value: null,
      },
      {
        label: 'Green',
        value: 0,
      },
      {
        label: 'Yellow',
        value: 1,
      },
      {
        label: 'Red',
        value: 2,
      },
    ],
    ratingColors: {
      0: 'success',
      1: 'warning',
      2: 'error',
    },
    icons: {
      mdiPencil,
      mdiDelete,
      mdiPlus,
      mdiCheck,
      mdiCircle,
      mdiChevronUp,
      mdiChevronDown,
    }
  }),
  computed: {
    headers() {
      if (!this.isSub) {
        return [
          {text: this.$t('countries'), value: 'countries'},
          {text: this.$t('preset'), value: 'preset'},
          {text: this.$t('rating'), value: 'rating'},
          {text: this.$t('sub_questions'), value: 'requireSubs', align: 'center'},
          {text: this.$t('answer_tags'), value: 'answerTags'},
          {text: this.$t('actions'), value: 'actions', align: 'right'},
        ];
      } else {
        return [
          {text: this.$t('countries'), value: 'countries'},
          {text: this.$t('preset'), value: 'preset'},
          {text: this.$t('rating'), value: 'rating'},
          {text: this.$t('answer_tags'), value: 'answerTags'},
          {text: this.$t('actions'), value: 'actions', align: 'right'},
        ];
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('add_group') : this.$t('edit_group');
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.matrix = [];
      if (this.question.country_groups && this.question.country_groups.length > 0) {
        for (const group of this.question.country_groups) {
          let item = {
            id: group.id,
            countries: group.countries,
            preset: group.preset,
            rating: this.ratings.find(r => r.value === group.result || 0).value,
            requireSubs: group.require_subs || false,
            answerTags: group.answer_tags || [],
          };
          this.matrix.push(item);
        }
      }
    },
    ratingLabel(item) {
      const rating = this.ratings.find(r => r.value === item.rating);
      return rating ? rating.label : '';
    },
    getIndex(item) {
      let index = -1;
      for (let idx = 0; idx < this.matrix.length; idx += 1) {
        if (item.id === this.matrix[idx].id) {
          index = idx;
        }
      }
      return index;
    },
    move(up, item) {
      let from = this.getIndex(item);
      let to = (up) ? from - 1 : from + 1;
      if (from > -1) {
        this.matrix.splice(to, 0, this.matrix.splice(from, 1)[0]);
      }
      this.storeOptions();
    },
    editItem(item) {
      this.editedIndex = this.matrix.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.matrix.indexOf(item);
      confirm(this.$t('confirm_delete_row')) && this.matrix.splice(index, 1);
      this.storeOptions();
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (!this.editedItem.id) {
        this.editedItem.id = Short.generate();
      }
      if (this.editedIndex > -1) {
        Object.assign(this.matrix[this.editedIndex], this.editedItem);
      } else {
        this.matrix.push(this.editedItem);
      }
      this.storeOptions();
      this.close();
    },
    storeOptions() {
      this.question.country_groups = [];
      for (const item of this.matrix) {
        this.question.country_groups.push({
          id: item.id ? item.id : Short.generate(),
          countries: item.countries,
          preset: item.preset,
          result: item.rating,
          require_subs: item.requireSubs,
          answer_tags: item.answerTags,
        });
      }
      this.$emit('changed');
    }
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.v-chip-group .v-chip {
  margin: 0 8px 0 0;
}
</style>
