import axios from 'axios';
import cookies from "js-cookie";
const base_url = (window.location.hostname === 'localhost') ? 'http://localhost:3005/inside-api' : '/inside-api';

const getData = async path => {
    let url =  base_url + path;
    try {
        const config = {
            headers: {'Authorization': `Bearer ${cookies.get('evt')}`}
        };
        const response = await axios.get(url,config);
        return response.data;
    } catch (error) {
        console.error('InsideApi: getData', url, error);
        return null;
    }
};

const postData = async (path, data) => {
    let url =  base_url + path;
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${cookies.get('evt')}`
            }
        };
        const response = await axios.post(url, data, config);
        return response.data;
    } catch (error) {
        console.error('InsideApi: postData', url, error);
        return {success: false}
    }
};

export default {
    getDefinition: async id => {
        let def = {};
        try {
            def = await getData('/v1/saq/definition/'.concat(id));
        } catch (error) {
            console.error('InsideApi: getDefinition', id, error)
        }
        return def;
    },

    getSupplierTags: async(isGlobalAdmin, client_id = null) => {
        let def = {};
        try {
            if (isGlobalAdmin){
                def = await getData(`/v1/admin/suppliers/tags/${client_id}`);
            }else {
                def = await getData('/v1/suppliers/tags');
            }
        } catch (error) {
            console.error('InsideApi: getDefinition', error)
        }
        return def;
    },

    translate: async (text, target) => {
        let t = {};
        try {
            t = await postData('/v1/language/translate', {target: target, text: text});
        } catch (error) {
            console.error('InsideApi: getTranslation', error)
        }
        return t;
    },

    saveDefinition: async (def, id) => {
        let resp = {success: false};
        try {
            resp = await postData('/v1/saq/definition/'.concat(id), def);
        } catch (error) {
            console.error('InsideApi: saveDefinition', id, error)
        }
        return resp;
    },
};

