<template>
  <section class="ingestion-settings">
    <v-select
        v-model="settings.mode"
        :items="modes"
        outlined
        :label="$t('ingestion_mode')"
        hide-details
        class="mb-4"
        @change="changeMode"
    ></v-select>
    <v-text-field
        v-model="settings.dataset_id"
        v-if="settings.mode === 'match-id'"
        outlined
        :label="$t('dataset_id')"
        hide-details
        class="mb-4"
        @change="saveSettings"
    >
    </v-text-field>
    <v-text-field
        v-model="settings.dataset_name"
        v-if="settings.mode === 'match-create-name'"
        outlined
        :label="$t('dataset_name')"
        hide-details
        class="mb-4"
        @change="saveSettings"
    >
    </v-text-field>
    <v-combobox
        v-model="settings.dataset_naming"
        v-if="settings.mode === 'dynamic-name'"
        :items="namingOptions"
        outlined
        :label="$t('dataset_name')"
        hide-details
        class="mb-4"
        @change="saveSettings"
    >
    </v-combobox>
    <v-combobox
        v-model="settings.dataset_naming_fallback"
        v-if="settings.mode === 'dynamic-name'"
        :items="namingOptions"
        outlined
        :label="$t('dataset_name_fallback')"
        hide-details
        class="mb-4"
        @change="saveSettings"
    >
    </v-combobox>
    <v-text-field
        v-model.trim="settings.dataset_name_suffix"
        v-if="settings.mode === 'dynamic-name'"
        outlined
        :label="$t('suffix')"
        hide-details
        class="mb-4"
        @change="saveSettings"
    >
    </v-text-field>
    <v-text-field
        v-model="settings.model_id"
        v-if="settings.mode"
        outlined
        :label="$t('dataset_model_id_if_create')"
        hide-details
        class="mb-4"
        @change="saveSettings"
    >
    </v-text-field>
    <div class="mb-4">
      <label class="mb-2">{{ $t('mappings') }}</label>
      <v-simple-table dense class="mapping-table mb-4" :key="`mappings-${refreshMappings}`">
        <thead>
        <tr>
          <th>{{ $t('value') }}</th>
          <th colspan="2">{{ $t('dataset_variable') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="key of Object.keys(settings.mappings)" :key="key">
          <td>{{ key }}</td>
          <td>{{ settings.mappings[key] }}</td>
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon small @click="deleteMapping(key)">
                  <v-icon v-on="on" small>{{ icons.mdiDelete }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('delete') }}</span>
            </v-tooltip>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <div class="d-flex">
        <v-select
            v-model="value"
            :items="valueOptions"
            dense
            :label="$t('value')"
            hide-details
            class="mr-4"
        >
        </v-select>
        <v-text-field
            v-model.trim="datasetVariable1"
            dense
            :label="$t('dataset_variable')"
            class="mr-4"
            hide-details
        >
        </v-text-field>
        <v-btn @click="addMapping" color="info" :disabled="!value || !datasetVariable1">{{ $t('add') }}</v-btn>
      </div>
    </div>
    <div class="mb-4" v-if="settings.questionnaire_variables">
      <label class="mb-2">{{ $t('questionnaire_variables') }}</label>
      <v-simple-table dense class="mapping-table mb-4" :key="`questionnaire-variables-${refreshQuestionnaireVariables}`">
        <thead>
        <tr>
          <th>{{ $t('questionnaire_variable') }}</th>
          <th colspan="2">{{ $t('dataset_variable') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="key of Object.keys(settings.questionnaire_variables)" :key="key">
          <td>{{ key }}</td>
          <td>{{ settings.questionnaire_variables[key] }}</td>
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon small @click="deleteQuestionnaireVariable(key)">
                  <v-icon v-on="on" small>{{ icons.mdiDelete }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('delete') }}</span>
            </v-tooltip>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <div class="d-flex">
        <v-combobox
            v-model.trim="questionnaireVariable"
            :items="variables"
            dense
            :label="$t('questionnaire_variable')"
            hide-details
            class="mr-4"
        >
        </v-combobox>
        <v-text-field
            v-model.trim="datasetVariable2"
            dense
            :label="$t('dataset_variable')"
            class="mr-4"
            hide-details
        >
        </v-text-field>
        <v-btn @click="addQuestionnaireVariable" color="info" :disabled="!questionnaireVariable || !datasetVariable2">{{ $t('add') }}</v-btn>
      </div>
    </div>
  </section>
</template>

<script>
import {mdiDelete} from '@mdi/js';

export default {
  name: 'IngestionSettings',
  props: {
    ingestionSettings: {
      type: Object,
      default: () => {
      },
    },
    saqType: String,
    questionMode: {
      type: Boolean,
      default: false,
    },
    variables: {
      type: Array,
      default: () => [],
    }
  },
  data: () => ({
    settings: {
      mappings: {},
      questionnaire_variables: {},
    },
    value: '',
    questionnaireVariable: '',
    datasetVariable1: '',
    datasetVariable2: '',
    refreshMappings: 1,
    refreshQuestionnaireVariables: 1,
    icons: {
      mdiDelete,
    }
  }),
  computed: {
    modes() {
      return [
        {value: 'match-id', text: this.$t('ingestionModes.match_dataset_id')},
        {value: 'match-create-name', text: this.$t('ingestionModes.match_create_dataset_name')},
        {value: 'dynamic-name', text: this.$t('ingestionModes.dynamic_dataset_name')},
      ];
    },
    namingOptions() {
      const options = [
        'context.company',
        'context.country',
      ];
      if (this.saqType === 'chain-data') {
        options.push(...[
          'chain.name',
          'chain.productName',
          'chain.tier',
          'chain.cpa',
          'chain.ownProductName',
          'chain.ownArticleNumber',
          'chain.ownGtin',
          'chain.articleNumber',
          'context.productName',
          'context.articleNumber',
        ]);
      }
      return options;
    },
    valueOptions() {
      const options = [
        'date',
        'supplierId',
        'respondentId',
        'context.company',
        'context.country',
        'context.registrationNumber',
        'context.vatNumber',
        'context.state',
        'context.email',
        'context.address',
        'context.postalCode',
        'context.city',
        'context.website',
        'context.operatingCountries',
        'context.sector',
        'context.nace',
      ];
      if (this.saqType === 'chain-data') {
        options.push(...[
          'chain.name',
          'chain.productName',
          'chain.articleNumber',
          'chain.cpa',
          'chain.tier',
          'chain.purchasingUnit',
          'chain.quantity',
          'chain.unit',
          'chain.spend',
          'chain.currency',
          'chain.gln',
          'chain.gtin',
          'chain.ownProductName',
          'chain.ownArticleNumber',
          'chain.ownGtin',
          'chain.useQuantity',
          'chain.useQuantityUnit',
          'chain.useWeightVolume',
          'chain.useWeightVolumeUnit',
          'context.supplierCompanyName',
          'context.supplierCountryCode',
          'context.supplierState',
          'context.supplierCrn',
          'context.customerCompanyName',
          'context.customerCountryCode',
          'context.productName',
          'context.articleNumber',
          'context.cpa',
          'context.gln',
          'context.gtin',
          'context.purchasingUnit',
          'context.useQuantity',
          'context.useQuantityUnit',
          'context.useWeightVolume',
          'context.useWeightVolumeUnit',
          'context.rootCustomerCompanyName',
          'context.rootCustomerCountryCode',
          'context.rootProductName',
          'context.rootArticleNumber',
          'context.rootCpa',
        ]);
      }
      return options;
    }
  },
  watch: {
    ingestionSettings: {
      immediate: true,
      handler(v) {
        this.settings = v ?? {};
        this.settings.mappings ??= {};
        if (this.questionMode) {
          this.settings.questionnaire_variables ??= {};
        } else {
          delete this.settings.questionnaire_variables;
        }
      },
    },
  },
  methods: {
    changeMode() {
      if (this.settings.mode === 'dynamic-name') {
        delete this.settings.dataset_id;
        delete this.settings.dataset_name;
      } else if (this.settings.mode === 'match-create-name') {
        delete this.settings.dataset_id;
        delete this.settings.dataset_naming;
        delete this.settings.dataset_naming_fallback;
      } else if (this.settings.mode === 'match-id') {
        delete this.settings.dataset_name;
        delete this.settings.dataset_naming;
        delete this.settings.dataset_naming_fallback;
      }
      this.saveSettings();
    },
    saveSettings() {
      this.$emit('save', this.settings);
    },
    addMapping() {
      this.settings.mappings[this.value] = this.datasetVariable1;
      this.value = '';
      this.datasetVariable1 = '';
      this.refreshMappings += 1;
      this.saveSettings();
    },
    deleteMapping(key) {
      delete this.settings.mappings[key];
      this.refreshMappings += 1;
      this.saveSettings();
    },
    addQuestionnaireVariable() {
      this.settings.questionnaire_variables[this.questionnaireVariable] = this.datasetVariable2;
      this.questionnaireVariable = '';
      this.datasetVariable2 = '';
      this.refreshQuestionnaireVariables += 1;
      this.saveSettings();
    },
    deleteQuestionnaireVariable(key) {
      delete this.settings.questionnaire_variables[key];
      this.refreshQuestionnaireVariables += 1;
      this.saveSettings();
    }
  }
};
</script>

<style scoped>
.mapping-table td:first-child, .mapping-table th:first-child {
  padding-left: 0px;
}
</style>
