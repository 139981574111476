<template>
  <v-app id="saq-editor">

    <!--DIALOG JSON-->
    <v-dialog v-model="dialogJson" width="800" max-width="800">
      <v-card>
        <v-card-title>{{ $t('edit_json') }}</v-card-title>
        <v-card-text>
          <vue-json-editor
              v-model="editorJson" :show-btns="false"
              :expanded-on-start="false"
              mode="tree"></vue-json-editor>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="dialogJson = false">{{ $t('button_close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="saveJson">{{ $t('button_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG SETTINGS-->
    <v-dialog v-model="dialogSettings" max-width="600" scrollable>
      <v-card>
        <v-card-title>{{ $t('settings') }}</v-card-title>
        <v-card-text>
          <v-text-field
              v-model="title"
              outlined
              :label="$t('title')"
              @change="titleChanged()"
              hide-details
              class="mb-4"
          >
          </v-text-field>
          <v-text-field
              v-model="display_title"
              outlined
              :label="$t('display_title')"
              @change="isChanged = true"
              hide-details
              class="mb-4"
          >
          </v-text-field>
          <v-select
              v-model="lang"
              outlined
              :items="languages"
              :label="$t('language')"
              @change="isChanged = true"
              hide-details
              class="mb-4"
          >
          </v-select>
          <v-checkbox
              v-model="supplementary"
              hide-details
              class="mt-0 mb-4"
              :label="$t('supplementary')"
              @change="isChanged = true">
          </v-checkbox>
          <v-select
              v-model="saqType"
              outlined
              :items="saqTypes"
              :label="$t('saq_type')"
              @change="isChanged = true"
              item-text="name"
              item-value="value"
              hide-details
              class="mb-4"
          >
          </v-select>
          <ingestion-settings
              v-if="saqType.includes('data')"
              :saq-type="saqType"
              :variables="variables"
              :question-mode="false"
              :ingestion-settings="ingestionSettings"
              @save="v => ingestionSettings = v"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogSettings = false">{{ $t('button_close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG TAGS-->
    <v-dialog v-model="dialogTags" max-width="1300" style="z-index: 300" scrollable>
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <TagsOverview :areas="areas" :userRol="userRol" :client_id="client_id" :tagPool="tagData"
                        @go-to-tag-question="goToTagQuestion"></TagsOverview>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogTags = false">{{ $t('button_close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG SAVE-->
    <v-dialog v-model="dialogSave" max-width="500">
      <v-card>
        <v-card-title>{{ $t('save') }}</v-card-title>
        <v-card-text>
          <v-checkbox v-model="saveAsCopy"
                      :label="$t('save_as_copy')"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="dialogSave = false">{{ $t('button_cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="save">{{ $t('button_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG COPY OR MOVE-->
    <v-dialog v-model="dialogCopyOrMove" max-width="500">
      <v-card v-if="draggedQuestion?.dragfrom === 'question-section'">
        <v-card-title>Move or copy question</v-card-title>
        <v-card-text>
          Do you want to move or copy the selected question?
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="draggedQuestion = null ; copyOrMoveInfo = {}; dialogCopyOrMove = false">
            {{ $t('button_cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="info" text @click="copyOrMoveQuestion(false,true)">{{ $t('button_move') }}</v-btn>
          <v-btn color="info" text @click="copyOrMoveQuestion(true,false)">{{ $t('button_copy') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG DELETE AREA -->
    <v-dialog v-model="dialogDeleteArea" max-width="500">
      <v-card>
        <v-card-title>{{ $t('confirm_generic') }}</v-card-title>
        <v-card-actions>
          <v-btn color="secondary" text @click="dialogDeleteArea = false">{{ $t('button_cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="deleteArea">{{ $t('button_yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG DELETE GROUP-->
    <v-dialog v-model="dialogDeleteGroup" max-width="500">
      <v-card>
        <v-card-title>{{ $t('confirm_generic') }}</v-card-title>
        <v-card-actions>
          <v-btn color="secondary" text @click="dialogDeleteGroup = false">{{ $t('button_cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="deleteGroup">{{ $t('button_yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG INSTRUCTIONS-->
    <v-dialog v-model="dialogInstructions" max-width="90%" scrollable>
      <v-card>
        <v-card-title>{{ $t('instructions') }}</v-card-title>
        <v-card-text>
          <v-checkbox v-model="def.instructions.show" :label="$t('show')" hide-details
                      class="mt-0 mb-4"></v-checkbox>
          <v-text-field dense outlined v-model="def.instructions.title" :label="$t('title')"></v-text-field>
          <html-editor outlined v-model="def.instructions.content" :label="$t('instructions')"></html-editor>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogInstructions = false">{{ $t('button_close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG STATISTICS TAGS-->
    <v-dialog v-model="dialogStatisticsTags" max-width="600">
      <v-card>
        <v-card-title>{{ $t('statistics_tags') }}</v-card-title>
        <v-card-text>
          <v-combobox v-model="currArea.statistics_tags"
                      :prepend-icon="icons.mdiTag"
                      :items="statisticsTags"
                      :search-input.sync="searchTags"
                      hide-selected
                      :label="$t('statistics_tags')"
                      multiple
                      deletable-chips
                      chips
                      @change="isChanged = true">
          </v-combobox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogStatisticsTags = false">{{ $t('button_close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG GROUP TAGS-->
    <v-dialog v-model="dialogGroupTags" max-width="600">
      <v-card>
        <v-card-title>{{ $t('question_group_tags') }}</v-card-title>
        <v-card-text>
          <v-combobox v-model="currGroup.inclusion_tags"
                      :prepend-icon="icons.mdiTagPlus"
                      :items="tagPool"
                      :search-input.sync="groupSearchInclusion"
                      hide-selected
                      :label="$t('inclusion_tags')"
                      multiple
                      deletable-chips
                      chips
                      @change="isChanged = true">
          </v-combobox>
          <v-combobox v-model="currGroup.exclusion_tags"
                      :prepend-icon="icons.mdiTagOff"
                      :items="tagPool"
                      :search-input.sync="groupSearchExclusion"
                      hide-selected
                      :label="$t('exclusion_tags')"
                      multiple
                      deletable-chips
                      chips
                      @change="isChanged = true">
          </v-combobox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogGroupTags = false">{{ $t('button_close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- APP NAVIGATION BAR-->
    <v-app-bar color="primary" app dark style="z-index: 210" clipped-left
               elevation="1">
      <v-toolbar-title v-text="appTitle"></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon v-if="isLoading" :loading="true"></v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="dialogInstructions = true">
              <v-icon>{{ icons.mdiInformationVariantBoxOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('instructions') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="dialogTags = true">
              <v-icon>{{ icons.mdiTag }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('tags_overview') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="dialogSettings = true">
              <v-icon>{{ icons.mdiCogs }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('settings') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="startPreview" :disabled="isChanged">
              <v-icon>{{ icons.mdiMonitorEye }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('preview') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="exportToExcel">
              <v-icon>{{ icons.mdiFileExportOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('export_to_excel_file') }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="userIsGlobalAdmin">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="openJsonEditor">
              <v-icon>{{ icons.mdiCodeJson }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('edit_json') }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="dialogSave = true" :loading="isSaving">
              <v-icon>{{ icons.mdiContentSave }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('save') }}</span>
        </v-tooltip>
      </v-toolbar-items>
    </v-app-bar>

    <!--SIDE-NAVIGATION-->
    <v-navigation-drawer v-model="drawer" :width="width" app permanent disable-resize-watcher clipped
                         style="background-color: #ffffff">
      <v-list dense class="mx-2">
        <draggable v-model="areas" group="areas" handle=".handleAreas" @start="draggedQuestion = null">
          <v-list-group
              v-model="area.active"
              class="mb-1"
              v-for="(area, aid) in areas"
              :key="area.id"
              no-action
              sub-group
              @click.stop="setArea(area, aid)"
              style="background-color: rgba(0,0,0,0.05);"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="max-width: 210px"><span :title="area.title">{{ area.title }}</span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon class="handleAreas grabber">{{ icons.mdiDragVertical }}</v-icon>
              </v-list-item-icon>
            </template>
            <draggable v-model="area.groups" group="groups" handle=".handleGroups" @start="draggedQuestion = null">
              <v-list-item
                  style="background-color:#ffffff"
                  v-for="(group, gid) in area.groups"
                  :key="group.id"
                  @drop="handleQuestionDrop(area.id, group.id)"
                  link
                  no-action
                  @click.stop="setGroup(area, aid, group, gid)">
                <div class="icon-to-left">
                  <v-icon class="handleGroups grabber">{{ icons.mdiDragVertical }}</v-icon>
                </div>
                <v-list-item-content>
                  <v-list-item-title v-text="group.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </draggable>
          </v-list-group>
        </draggable>
        <v-list-item
            style="background-color:#ffffff"
            link
            no-action
            @click="preAddArea(); addArea()">
          <v-icon color="info" class="mr-3">{{ icons.mdiFolderHomeOutline }}</v-icon>
          <v-list-item-content>
            <v-list-item-title v-text="'Add ' + $t('area') "></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            style="background-color:#ffffff"
            link
            no-action
            @click="preAddGroup(); addGroup()">
          <v-icon color="info" class="mr-3">{{ icons.mdiFolderMultiplePlusOutline }}</v-icon>
          <v-list-item-content>
            <v-list-item-title v-text="'Add ' + $t('question_group') "></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- MAIN QUESTION SECTION -->
    <v-container fluid>
      <v-main>
        <v-form>
          <div class="flex-fill d-flex justify-space-between mt-2 mb-4">
            <v-text-field v-model.trim="currArea.title" outlined dense hide-details :label="$t('area')"
                          @change="isChanged = true"></v-text-field>
            <div class="buttons">
              <!--DELETE AREA -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click.stop="dialogDeleteArea = true">
                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                  </v-btn>
                </template>
                <span>Delete area</span>
              </v-tooltip>
              <!--STATISTICS TAGS -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon v-if="areaTagged" color="info" @click="dialogStatisticsTags = true">{{ icons.mdiChartBar }}
                    </v-icon>
                    <v-icon v-if="!areaTagged" color="info" @click="dialogStatisticsTags = true">{{ icons.mdiChartBar }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Statistics tags</span>
              </v-tooltip>
            </div>
          </div>
          <div class="d-flex justify-space-between">
            <v-text-field v-model.trim="currGroup.title" outlined dense hide-details
                          :label="$t('question_group')" @change="isChanged = true"></v-text-field>
            <div class="buttons">
              <!--DELETE GROUP -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click.stop="dialogDeleteGroup = true">
                    <v-icon>{{ icons.mdiDelete }}</v-icon>
                  </v-btn>
                </template>
                <span>Delete group</span>
              </v-tooltip>
            </div>
          </div>
          <v-divider class="my-4"></v-divider>
          <div id="questions" v-if="!!currGroup.id">
            <v-expansion-panels multiple focusable v-model="openedPanel" accordion>
              <draggable style="width: 100%" :list="currGroup.questions" handle=".handle"
                         @start="handleQuestionDragStart">
                <v-expansion-panel v-for="(currQuestion,currQuestionIndex) of currGroup.questions"
                                   :key="currQuestionIndex" class="mb-3">
                  <!-- QUESTION HEADER -->
                  <v-expansion-panel-header color="primary" style="color: white" class="ma-0 pa-2">
                    <template v-slot:actions>
                      <v-icon color="white">$expand</v-icon>
                    </template>
                    <span class="text-truncate">
                        <v-icon class="mr-3 handle grabber" color="white">{{ icons.mdiDragVertical }}</v-icon> {{
                        currQuestionIndex + 1
                      }} - {{ currQuestion.question }}
                      </span>
                  </v-expansion-panel-header>
                  <!-- QUESTION CONTENT -->
                  <v-expansion-panel-content class="mt-5 mb-7">
                    <question-settings
                        :question="currQuestion"
                        :index="currQuestionIndex"
                        :tag-pool="tagPool"
                        :languages="languages"
                        :saq-type="saqType"
                        :variables="variables"
                        :userRol="userRol"
                        :lang="lang"
                        :comparison-fields="comparisonFields"
                        :is-sub="false"
                        :key="`${currQuestion.id}-${componentKey}`"
                        v-on:changed="isChanged = true"
                        v-on:question="setQuestion"
                        v-on:refresh="refreshQuestion"
                        v-on:delete="deleteQuestion"
                        v-on:copy="copyQuestion"
                    >
                    </question-settings>
                    <v-menu top :close-on-click="true">
                      <template v-slot:activator="{ on }">
                        <v-btn
                            color="info"
                            fab
                            small
                            absolute
                            bottom
                            right
                            v-on="on">
                          <v-icon>{{ icons.mdiPlus }}</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item dense @click.stop="addQuestion(currQuestionIndex)">
                          <v-list-item-title>{{ $t('question') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item dense @click.stop="addSubQuestion(currQuestion)">
                          <v-list-item-title>{{ $t('sub_question') }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="copiedQuestion" dense @click.stop="addQuestion(currQuestionIndex, true)">
                          <v-list-item-title>{{ $t('paste_question') }}</v-list-item-title>
                        </v-list-item>
                        <v-list-item v-if="copiedQuestion" dense @click.stop="addSubQuestion(currQuestion, true)">
                          <v-list-item-title>{{ $t('paste_as_sub_question') }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <div v-if="currQuestion.subquestions && currQuestion.subquestions.length > 0">
                      <div>
                        <v-icon large class="mt-5">{{ icons.mdiSubdirectoryArrowRight }}</v-icon>
                      </div>
                      <div class="flex-fill">
                        <div v-for="(currSubQuestion, currSubQuestionIndex) in currQuestion.subquestions"
                             :key="currSubQuestion.id">
                          <v-card class="mb-3">
                            <v-card-text>
                              <question-settings
                                  :question="currSubQuestion"
                                  :index="currSubQuestionIndex"
                                  :tag-pool="tagPool"
                                  :saq-type="saqType"
                                  :variables="variables"
                                  :languages="languages"
                                  :lang="lang"
                                  :userRol="userRol"
                                  :comparison-fields="comparisonFields"
                                  :is-sub="true"
                                  :key="`${currSubQuestion.id}-${componentKey}`"
                                  v-on:changed="isChanged = true"
                                  v-on:question="setQuestion"
                                  v-on:refresh="refreshQuestion"
                                  v-on:delete="deleteQuestion"
                              ></question-settings>
                            </v-card-text>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </draggable>
            </v-expansion-panels>
          </div>
        </v-form>
      </v-main>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="timeout">{{ snackbarMessage }}</v-snackbar>
  </v-app>
</template>

<style scoped>
.icon-to-right v-icon {
  margin-left: auto;
}

.v-list-group__activator .v-icon {
  order: 2;
}

.v-application--is-ltr .v-list-group--sub-group .v-list-group__items .v-list-item {
  padding-left: 0;
}

.icon-to-left {
  margin-right: 8px;
  margin-left: 25px;
}

.grabber {
  cursor: grab;
}

.grabber:active {
  cursor: grabbing;
}
</style>

<script>
import api from './inside-api';
import Short from './shortuid';
import draggable from 'vuedraggable';
import {
  mdiCodeJson,
  mdiCogs,
  mdiContentSave,
  mdiDelete,
  mdiFileExportOutline,
  mdiFocusFieldHorizontal,
  mdiMonitorEye,
  mdiPlus,
  mdiSubdirectoryArrowRight,
  mdiTag,
  mdiTagOff,
  mdiTagPlus,
  mdiChatQuestionOutline,
  mdiFolderCogOutline,
  mdiChartBar,
  mdiDragVertical,
  mdiFolderMultiplePlusOutline,
  mdiFolderHomeOutline,
  mdiInformationVariantBoxOutline,
} from '@mdi/js';
import QuestionSettings from './components/QuestionSettings';
import vueJsonEditor from 'vue-json-editor';
import zipcelx from 'zipcelx';
import _cloneDeep from 'lodash/cloneDeep';
import './assets/css/vue-json-editor-overrides.css';
import short from './shortuid';
import TagsOverview from './components/TagsOverview.vue';
import HtmlEditor from './components/HtmlEditor.vue';
import IngestionSettings from './components/IngestionSettings.vue';

function getOptions(options) {
  let r = [];
  for (const option of options) {
    let i = option.title;
    if (option.result === 0) {
      i = i.concat(' (Green)');
    } else if (option.result === 1) {
      i = i.concat(' (Yellow)');
    } else if (option.result === 2) {
      i = i.concat(' (Red)');
    }
    r.push(i);
  }
  return r;
}

function getRating(result) {
  let rating = '';
  if (result === 0) {
    rating = 'Green';
  } else if (result === 1) {
    rating = 'Yellow';
  } else if (result === 2) {
    rating = 'Red';
  }
  return rating;
}

function parseXRow(question, area, group, parent) {
  const qTypes = {
    text: 'Text',
    longtext: 'Long text',
    number: 'Number',
    date: 'Date',
    select: 'Select',
    slider: 'Slider',
    sc: 'Single choice',
    mc: 'Multiple choice',
    country: 'Country selector',
    countries: 'Country selector (multiple)',
    certificate: 'Certificate',
    certificatetable: 'Certificate table',
    unittable: 'Unit table',
    principaltable: 'Principal table',
    companytable: 'Company (group structure/affiliates) table',
    ownertable: 'Owner table',
    customtable: 'Custom table',
    bool: 'True / False',
    files: 'File uploads',
    nace: 'NACE code selector',
    sectors: 'Sector selector',
    suppliers: 'Supplier table',
  };
  const row = [
    {type: 'string', value: question.id},
    {type: 'string', value: question.ref || ''},
    {type: 'string', value: area},
    {type: 'string', value: group},
    {type: 'string', value: parent},
    {type: 'string', value: question.question},
    {type: 'string', value: question.info || ''},
    {type: 'string', value: (qTypes[question.type]) ? qTypes[question.type] : ''},
    {
      type: 'string',
      value: (question.type === 'bool') ?
          question.text_true.concat(' (', getRating(question.result_true), '), ', question.text_false, ' (', getRating(question.result_false), ')') :
          (question.options) ? getOptions(question.options).join(', ') : '',
    },
    {
      id: 'answer_tags',
      type: 'string',
    },
    {type: 'string', value: Array.isArray(question.inclusion_tags) ? question.inclusion_tags.join(', ') : ''},
    {type: 'string', value: Array.isArray(question.exclusion_tags) ? question.exclusion_tags.join(', ') : ''},
    {type: 'string', value: Array.isArray(question.columns) ? question.columns.map(i => i.prop).join(', ') : question.variable ?? ''},
  ];
  let answerTags = [];
  if (question.type === 'bool') {
    answerTags.push({text: question.text_true, tags: question.answer_tags_true});
    answerTags.push({text: question.text_false, tags: question.answer_tags_false});
  } else if (question.type === 'number' || question.type === 'slider') {
    answerTags.push({text: 'Over', tags: question.answer_tags_over});
    answerTags.push({text: 'Under', tags: question.answer_tags_under});
  } else if (Array.isArray(question.options)) {
    answerTags = question.options.map(i => ({text: i.title, tags: i.answer_tags}));
  }
  row[row.findIndex(i => i.id === 'answer_tags')].value = answerTags.filter(i => Array.isArray(i.tags) && i.tags.length > 0).map(i => `${i.text}: ${i.tags.join(', ')}`).join('; ');
  return row;
}

export default {
  name: 'Editor',
  props: {
    init_id: String,
    copy_id: String,
    set_client_id: String,
    userRol: Array,
    userEnv: String,
  },
  components: {
    IngestionSettings,
    TagsOverview,
    'question-settings': QuestionSettings,
    draggable,
    'vue-json-editor': vueJsonEditor,
    HtmlEditor,
  },
  watch: {
    appTitle(v) {
      window.document.title = `SAQ editor – ${v}`;
    },
    isChanged: function () {
      if (this.isChanged) {
        window.onbeforeunload = function () {
          return true;
        };
      } else {
        window.onbeforeunload = null;
      }
    },
    areas: {
      deep: true,
      handler() {
        this.extractTags();
        this.extractVariables();
      },
    },
  },
  created() {
    this.start();
  },
  computed: {
    appTitle() {
      let title = '';
      if (this.companyName) {
        title = this.companyName + ' – ';
      }
      if (this.isChanged) {
        return title.concat(this.title, ' *');
      } else {
        return title.concat(this.title);
      }
    },
    areaTagged() {
      return (this.currArea.statistics_tags && this.currArea.statistics_tags.length > 0);
    },
    comparisonFields() {
      let fields = [];
      let negativeLabel = this.$t('negative');
      this.areas.forEach(function (area) {
        area.groups.forEach(function (group) {
          group.questions.forEach(function (field) {
            if (field.type === 'number') {
              fields.push({
                id: field.id,
                question: field.question,
              });
              fields.push({
                id: `-${field.id}`,
                question: `${field.question} (${negativeLabel})`,
              });
            }
            if (field.subquestions) {
              field.subquestions.forEach(function (subfield) {
                if (subfield.type === 'number') {
                  fields.push({
                    id: subfield.id,
                    question: subfield.question,
                  });
                  fields.push({
                    id: `-${subfield.id}`,
                    question: `${subfield.question} (${negativeLabel})`,
                  });
                }
              });
            }
          });
        });
      });
      return fields;
    },
    tagData() {
      return this.extractTags();
    },
    userIsGlobalAdmin() {
      return this.userRol.includes('globalAdmin');
    },
  },
  data: () => ({
    session: {},
    openedPanel: [],
    companyName: '',
    drawer: true,
    dialogCopyOrMove: false,
    dialogInstructions: false,
    draggedQuestion: null,
    copyOrMoveInfo: {},
    width: '350px',
    client_id: null,
    def_id: '',
    def: {
      instructions: {},
    },
    areas: [],
    editorJson: [],
    currArea: {},
    currAreaIndex: -1,
    currGroupIndex: -1,
    currGroup: {},
    variables: [],
    lang: '',
    ingestionSettings: {},
    tagPool: [],
    supplierTags: [],
    searchTags: null,
    groupSearchInclusion: null,
    groupSearchExclusion: null,
    over: false,
    saqType: 'standard',
    saqTypes: [{name: 'Standard', value: 'standard'}, {name: 'Data', value: 'data'}, {
      name: 'Chain data',
      value: 'chain-data'
    }],
    copiedQuestion: null,
    saveAsCopy: false,
    dialogStatisticsTags: false,
    dialogGroupTags: false,
    dialogJson: false,
    dialogSave: false,
    dialogSettings: false,
    dialogTags: false,
    dialogDeleteArea: false,
    dialogDeleteGroup: false,
    dialogTexts: false,
    title: 'Untitled',
    display_title: '',
    supplementary: false,
    isLoading: false,
    isSaving: false,
    isChanged: false,
    isError: false,
    timeout: 3000,
    snackbar: false,
    snackbarMessage: '',
    componentKey: 0,
    icons: {
      mdiContentSave,
      mdiFocusFieldHorizontal,
      mdiMonitorEye,
      mdiTag,
      mdiDragVertical,
      mdiChartBar,
      mdiTagPlus,
      mdiFolderCogOutline,
      mdiTagOff,
      mdiChatQuestionOutline,
      mdiPlus,
      mdiFolderHomeOutline,
      mdiFolderMultiplePlusOutline,
      mdiSubdirectoryArrowRight,
      mdiDelete,
      mdiCodeJson,
      mdiCogs,
      mdiFileExportOutline,
      mdiInformationVariantBoxOutline,
    },
    statisticsTags: ['anti-corruption', 'environment', 'general', 'governance', 'social', 'quality'],
    languages: ['en', 'sv', 'no', 'da', 'fi', 'fr', 'de', 'es', 'it', 'pt', 'pl', 'ru', 'is', 'et', 'lv', 'lt', 'ja', 'ko', 'nl', 'cs', 'ar', 'fa', 'hi', 'zh', 'bn'],
  }),
  methods: {
    setQuestion(payload) {
      if (payload.id) {
        for (const area of this.areas) {
          for (const group of area.groups) {
            for (let qi = 0; qi < group.questions.length; qi++) {
              if (group.questions[qi].id === payload.id) {
                this.$set(group.questions, qi, payload.question);
                console.debug(group.questions[qi]);
              }
              if (group.questions[qi].subquestions && Array.isArray(group.questions[qi].subquestions)) {
                for (let i = 0; i < group.questions[qi].subquestions.length; i++) {
                  if (group.questions[qi].subquestions[i].id === payload.id) {
                    this.$set(group.questions[qi].subquestions, i, payload.question);
                    console.debug(group.questions[qi].subquestions[i]);
                  }
                }
              }
            }
          }
        }
        this.$forceUpdate();
      }
    },
    startPreview() {
      window.open('https://saq.evaluate.se/'.concat(this.def_id, '?mode=preview'));
    },
    openJsonEditor() {
      this.editorJson = _cloneDeep(this.areas);
      this.dialogJson = true;
    },
    saveJson() {
      try {
        this.dialogJson = false;
        let test = JSON.parse(JSON.stringify(this.editorJson));
        if (test.length > 0) {
          this.areas = _cloneDeep(this.editorJson);
          this.isChanged = true;
        }
      } catch {
        alert('JSON error');
      }
    },
    refreshQuestion() {
      this.isChanged = true;
      this.componentKey += 1;
    },
    exportToExcel() {
      let xData = [];
      const titles = ['ID', 'Ref', 'Area', 'Group', 'Parent', 'Question', 'Risk indication text', 'Type', 'Options', 'Answer tags', 'Inclusion tags', 'Exclusion tags', 'Variable(s)'];
      const titleRow = [];
      titles.forEach(function (title) {
        titleRow.push({
          value: title,
          type: 'string',
        });
      });
      xData.push(titleRow);
      const areas = _cloneDeep(this.areas);
      for (const area of areas) {
        area.groups.forEach(function (group) {
          group.questions.forEach(function (question) {
            xData.push(parseXRow(question, area.title, group.title, ''));
            if (question.subquestions && Array.isArray(question.subquestions)) {
              question.subquestions.forEach(function (subquestion) {
                xData.push(parseXRow(subquestion, area.title, group.title, question.id));
              });
            }
          });
        });
      }
      const xConfig = {
        filename: this.title,
        sheet: {
          data: xData,
        },
      };
      zipcelx(xConfig);
    },
    setArea(area, areaIndex) {
      this.isLoading = true;
      this.currArea = area;
      this.currGroup = area.groups[0];
      this.currAreaIndex = areaIndex;
      this.componentKey += 1;
      this.isLoading = false;
    },
    setGroup(area, areaIndex, group, groupIndex) {
      this.isLoading = true;
      this.currArea = area;
      this.currAreaIndex = areaIndex;
      this.currGroup = group;
      this.currGroupIndex = groupIndex;
      this.componentKey += 1;
      this.isLoading = false;
    },
    prepareDefCopy(def) {
      const cleanIngestion = obj => {
        if (obj.ingestion) {
          obj.ingestion = {
            ...obj.ingestion?.mapping && {mapping: obj.ingestion.mapping},
            ...obj.ingestion?.questionnaire_variables && {questionnaire_variables: obj.ingestion.questionnaire_variables},
          };
        }
      };
      cleanIngestion(def);
      if (Array.isArray(def.areas)) {
        for (const area of def.areas) {
          for (const group of area.groups) {
            for (const question of group.questions) {
              question.id = short.generate();
              cleanIngestion(question);
              if (Array.isArray(question.subquestions)) {
                for (const subquestion of question.subquestions) {
                  subquestion.id = short.generate();
                  cleanIngestion(subquestion);
                }
              }
            }
          }
        }
      }
      return def;
    },
    async start() {
      this.isLoading = true;
      this.companyName = this.$route.query?.company;
      if (this.init_id || (this.copy_id && this.copy_id !== 'new')) {
        try {
          const def = await api.getDefinition(this.init_id || this.copy_id);
          if (this.copy_id) {
            this.def = this.prepareDefCopy(def);
          } else {
            this.def = def;
          }
          if (this.def.title) {
            this.def_id = this.copy_id ? Short.generate() : this.init_id;
            this.saqType = this.def.saq_type ?? 'standard';
            this.def.id = this.def_id;
            this.title = this.def.title;
            this.display_title = this.def.display_title || '';
            this.supplementary = !!this.def.supplementary;
            this.lang = this.def.lang;
            this.client_id = this.copy_id ? this.set_client_id : this.def.client_id;
            this.def.client_id = this.client_id;
            this.areas = this.def.areas;
            this.def.areas = this.areas;
            this.def.instructions ??= {};
            this.ingestionSettings = this.def.ingestion ?? {};
            if (this.def.questions) {
              delete this.def.questions;
            }
            this.isChanged = !!this.copy_id;
          }
          this.setArea(this.areas[0], 0);
          this.setGroup(this.areas[0], 0, this.areas[0].groups[0], 0);
        } catch {
          this.isError = true;
        }
      } else if (this.set_client_id) {
        this.title = 'Untitled';
        this.lang = 'en';
        this.client_id = this.set_client_id;
        this.env = this.userEnv ?? 'p';
        this.def_id = Short.generate();
        this.currArea = this.newArea('General', 'gen');
        this.currAreaIndex = 0;
        this.currArea.groups = [this.newGroup('Untitled')];
        this.currArea.groups[0].questions = [this.newQuestion()];
        this.areas.push(this.currArea);
        this.currGroupIndex = 0;
        this.ingestionSettings = {};
        this.isChanged = true;
        this.setArea(this.areas[0], 0);
        this.setGroup(this.areas[0], 0, this.areas[0].groups[0], 0);
      } else {
        this.isError = true;
      }
      this.getSupplierTags();
      this.extractTags();
      this.isLoading = false;
    },
    addArea() {
      let area = this.newArea('Untitled');
      let group = this.newGroup('Untitled');
      group.questions.push(this.newQuestion());
      area.groups.push(group);
      if (this.currAreaIndex === this.areas.length - 1) {
        this.areas.push(area);
      } else {
        this.areas.splice(this.currAreaIndex + 1, 0, area);
      }
      this.isChanged = true;
      this.areas[this.currAreaIndex].active = true;
    },
    newArea(title) {
      return {
        title: title,
        id: Short.generate(),
        tags: [],
        groups: [],
      };
    },
    addGroup() {
      let group = this.newGroup('Untitled');
      group.questions.push(this.newQuestion());
      if (this.currGroupIndex === this.areas[this.currAreaIndex].groups.length - 1) {
        this.areas[this.currAreaIndex].groups.push(group);
      } else {
        this.areas[this.currAreaIndex].groups.splice(this.currGroupIndex + 1, 0, group);
      }
      this.isChanged = true;
    },
    newGroup(title) {
      return {
        title: title,
        id: Short.generate(),
        questions: [],
        inclusion_tags: [],
        exclusion_tags: [],
      };
    },
    addQuestion(index, paste) {
      let newQ = paste && this.copiedQuestion ? this.copiedQuestion : this.newQuestion();
      if (this.currGroup.questions.length > index) {
        this.currGroup.questions.splice(index + 1, 0, newQ);
      } else {
        this.currGroup.questions.push(newQ);
      }
      this.isChanged = true;
    },
    addSubQuestion(question, paste) {
      let newQ = paste && this.copiedQuestion ? this.copiedQuestion : this.newQuestion();
      if (!question.subquestions) {
        question.subquestions = [newQ];
      } else {
        question.subquestions.push(newQ);
      }
      this.isChanged = true;
      this.$forceUpdate();
    },
    newQuestion() {
      return {
        id: Short.generate(),
        type: 'text',
        question: '',
      };
    },
    deleteQuestion(id) {
      for (let idx = 0; idx < this.currGroup.questions.length; idx += 1) {
        if (this.currGroup.questions[idx].id === id) {
          this.currGroup.questions.splice(idx, 1);
        } else if (this.currGroup.questions[idx].subquestions) {
          for (let sidx = 0; sidx < this.currGroup.questions[idx].subquestions.length; sidx += 1) {
            if (this.currGroup.questions[idx].subquestions[sidx].id === id) {
              this.currGroup.questions[idx].subquestions.splice(sidx, 1);
            }
          }
        }
      }
      this.isChanged = true;
      this.$forceUpdate();
    },
    deleteArea() {
      this.dialogDeleteArea = false;
      this.areas.splice(this.currAreaIndex, 1);
      if (this.areas.length === 0) {
        this.currAreaIndex = -1;
        this.addArea();
      }
      this.setArea(this.areas[0], 0);
      this.isChanged = true;
    },
    deleteGroup() {
      this.dialogDeleteGroup = false;
      this.areas[this.currAreaIndex].groups.splice(this.currGroupIndex, 1);
      if (this.areas[this.currAreaIndex].groups.length === 0) {
        this.currGroupIndex = -1;
        this.addGroup();
      }
      this.setGroup(this.currArea, this.currAreaIndex, this.areas[this.currAreaIndex].groups[0], 0);
      this.isChanged = true;
    },
    titleChanged() {
      window.document.title = `SAQ editor – ${this.title}`;
      this.isChanged = true;
    },
    cleanAreas() {
      for (let idx = 0; idx < this.areas.length; idx += 1) {
        let area = this.areas[idx];
        if (!area.statistics_tags) {
          area.statistics_tags = [];
        }
        for (let gidx = 0; gidx < area.groups.length; gidx += 1) {
          let group = area.groups[gidx];
          if (!group.inclusion_tags) {
            group.inclusion_tags = [];
          }
          if (!group.exclusion_tags) {
            group.exclusion_tags = [];
          }
        }
      }
    },
    copyQuestion(question) {
      this.copiedQuestion = {
        ...question,
        id: Short.generate(),
        ...Array.isArray(question.subquestions) && {subquestions: question.subquestions.map(i => ({...i, id: Short.generate()}))},
      };
      this.snackbarMessage = 'Question copied';
      this.snackbar = true;
    },
    async save() {
      this.isSaving = true;
      this.def.id = this.def_id;
      if (!this.def.active) {
        this.def.active = false;
      }
      this.def.lang = this.lang;
      this.def.client_id = this.client_id;
      this.def.title = this.title;
      this.def.saq_type = this.saqType;
      this.def.display_title = this.display_title;
      this.def.supplementary = this.supplementary;
      this.def.ingestion = this.ingestionSettings;
      this.cleanAreas();
      this.def.areas = this.areas;
      let id = this.def_id;
      const copy = this.saveAsCopy;
      this.saveAsCopy = false;
      if (copy) {
        id = Short.generate();
        this.def.ts_added = new Date();
      }
      if (copy || this.copy_id) {
        this.def.copy = true;
      }
      this.isChanged = false;
      const result = await api.saveDefinition(this.def, id);
      this.isSaving = false;
      this.dialogSave = false;
      if (result.success) {
        if (window.opener && window.opener.location) {
          window.opener.location.reload();
        }
        if (copy || this.copy_id) {
          window.onbeforeunload = null;
          window.location.href = this.companyName ? `/${id}?company=${this.companyName}` : `/${id}`;
        } else {
          this.snackbarMessage = 'Saved successfully';
          this.snackbar = true;
        }
      } else {
        this.snackbarMessage = 'Could not save';
        this.snackbar = true;
      }
    },
    handleQuestionDragStart(evt) {
      this.draggedQuestion = this.currGroup.questions[evt.oldIndex];
      this.draggedQuestion.dragfrom = 'question-section';
    },
    handleQuestionDrop(areaId, groupId) {
      if (this.draggedQuestion?.dragfrom === 'question-section') {
        this.dialogCopyOrMove = true;
        this.copyOrMoveInfo = {areaId: areaId, groupId: groupId};
      }
    },
    copyOrMoveQuestion(copy, move) {
      // MOVE QUESTION
      if (move) {
        delete this.draggedQuestion.dragfrom;
        const area = this.areas.find(area => area.id === this.copyOrMoveInfo.areaId);
        const group = area.groups.find(group => group.id === this.copyOrMoveInfo.groupId);
        group.questions.push(this.draggedQuestion);
        const oldGroup = this.currGroup.questions;
        oldGroup.splice(oldGroup.indexOf(this.draggedQuestion), 1);
      }
      // COPY QUESTION
      if (copy) {
        delete this.draggedQuestion.dragfrom;
        const questionToCopy = _cloneDeep(this.draggedQuestion);
        questionToCopy.id = short.generate();
        if (questionToCopy?.subquestions?.length) {
          questionToCopy.subquestions.forEach((question) => question.id = short.generate());
        }
        const area = this.areas.find(area => area.id === this.copyOrMoveInfo.areaId);
        const group = area.groups.find(group => group.id === this.copyOrMoveInfo.groupId);
        group.questions.push(questionToCopy);
      }
      this.componentKey += 1;
      this.draggedQuestion = null;
      this.dialogCopyOrMove = false;
    },
    goToTagQuestion(data) {
      const getArea = this.areas.find(x => x.id === data.areaId);
      const getGroup = getArea.groups.find(x => x.id === data.groupId);
      this.closeAllPanels();
      this.setGroup(getArea, data.areaIndex, getGroup, data.groupIndex);
      this.openPanel(data.questionIndex - 1);
      this.dialogTags = false;
      this.areas.forEach(area => {
        area.active = false;
      });
      this.areas[data.areaIndex].active = true;
    },
    extractVariables() {
      const variables = [];
      this.areas.forEach(area => {
        area.groups.forEach(group => {
          group.questions.forEach(question => {
            if (question.variable) {
              variables.push(question.variable);
            }
            if (Array.isArray(question.subquestions)) {
              question.subquestions.forEach(subquestion => {
                if (subquestion.variable) {
                  variables.push(subquestion.variable);
                }
              });
            }
          });
        });
      });
      this.variables = variables;
    },
    extractTags() {
      let tags = [];
      this.areas.forEach((area, areaIndex) => {
        area.groups.forEach((group, groupIndex) => {
          group.questions.forEach((question, index) => {

            // Question answer and inclusion/exclusion tags
            if (question.answer_tags_true && question.answer_tags_true.length > 0) {
              question.answer_tags_true.forEach(tag => {
                tags.push({
                  category: 'answerTagsTrue',
                  name: tag,
                  question_id: question.id,
                  question: question.question,
                  area_index: areaIndex,
                  group_index: groupIndex,
                  question_index: index + 1,
                  groupTitle: group.title,
                  areaTitle: area.title,
                  area_id: area.id,
                  group_id: group.id,
                });
              });
            }
            if (question.answer_tags_false && question.answer_tags_false.length > 0) {
              question.answer_tags_false.forEach(tag => {
                tags.push({
                  category: 'answerTagsFalse',
                  name: tag,
                  question_id: question.id,
                  question: question.question,
                  area_index: areaIndex,
                  group_index: groupIndex,
                  question_index: index + 1,
                  groupTitle: group.title,
                  areaTitle: area.title,
                  area_id: area.id,
                  group_id: group.id,
                });
              });
            }
            if (question.exclusion_tags && question.exclusion_tags.length > 0) {
              question.exclusion_tags.forEach(tag => {
                tags.push({
                  category: 'exclusionTags',
                  name: tag,
                  question_id: question.id,
                  question: question.question,
                  area_index: areaIndex,
                  group_index: groupIndex,
                  question_index: index + 1,
                  groupTitle: group.title,
                  areaTitle: area.title,
                  area_id: area.id,
                  group_id: group.id,
                });
              });
            }
            if (question.inclusion_tags && question.inclusion_tags.length > 0) {
              question.inclusion_tags.forEach(tag => {
                tags.push({
                  category: 'inclusionTags',
                  name: tag,
                  question_id: question.id,
                  question: question.question,
                  area_index: areaIndex,
                  group_index: groupIndex,
                  question_index: index + 1,
                  groupTitle: group.title,
                  areaTitle: area.title,
                  area_id: area.id,
                  group_id: group.id,
                });
              });
            }

            // QUESTION OPTION TAGS
            if (Array.isArray(question.options)) {
              let shallowOptions = [...question.options]; //removes error undefined
              shallowOptions.forEach((option) => {
                if (Array.isArray(option.answer_tags)) {
                  option.answer_tags.forEach((tag) => {
                    tags.push({
                      category: 'optionTag',
                      name: tag,
                      question_id: question.id,
                      question: question.question,
                      area_index: areaIndex,
                      group_index: groupIndex,
                      question_index: index + 1,
                      groupTitle: group.title,
                      areaTitle: area.title,
                      area_id: area.id,
                      group_id: group.id,
                    });
                  });
                }
              });
            }

            // SUB-QUESTION answer and inclusion/exclusion tags
            if (Array.isArray(question.subquestions)) {
              let subQuestions = [...question.subquestions]; //to remove __ob__: Observer
              subQuestions.forEach((subQuestion) => {
                const subQ = {...subQuestion};  // to remove __ob__: Observer
                if (subQ.answer_tags_true && subQ.answer_tags_true.length > 0) {
                  subQ.answer_tags_true.forEach(tag => {
                    tags.push({
                      category: 'answerTagsTrue',
                      name: tag,
                      question_id: subQ.id,
                      question: subQ.question,
                      area_index: areaIndex,
                      group_index: groupIndex,
                      question_index: index + 1,
                      groupTitle: group.title,
                      areaTitle: area.title,
                      area_id: area.id,
                      group_id: group.id,
                    });
                  });
                }
                if (subQ.answer_tags_false && subQ.answer_tags_false.length > 0) {
                  subQ.answer_tags_false.forEach(tag => {
                    tags.push({
                      category: 'answerTagsFalse',
                      name: tag,
                      question_id: subQ.id,
                      question: subQ.question,
                      area_index: areaIndex,
                      group_index: groupIndex,
                      question_index: index + 1,
                      groupTitle: group.title,
                      areaTitle: area.title,
                      area_id: area.id,
                      group_id: group.id,
                    });
                  });
                }
                if (subQ.exclusion_tags && subQ.exclusion_tags.length > 0) {
                  subQ.exclusion_tags.forEach(tag => {
                    tags.push({
                      category: 'exclusionTags',
                      name: tag,
                      question_id: subQ.id,
                      question: subQ.question,
                      area_index: areaIndex,
                      group_index: groupIndex,
                      question_index: index + 1,
                      groupTitle: group.title,
                      areaTitle: area.title,
                      area_id: area.id,
                      group_id: group.id,
                    });
                  });
                }
                if (subQ.inclusion_tags && subQ.inclusion_tags.length > 0) {
                  subQ.inclusion_tags.forEach(tag => {
                    tags.push({
                      category: 'inclusionTags',
                      name: tag,
                      question_id: subQ.id,
                      question: subQ.question,
                      area_index: areaIndex,
                      group_index: groupIndex,
                      question_index: index + 1,
                      groupTitle: group.title,
                      areaTitle: area.title,
                      area_id: area.id,
                      group_id: group.id,
                    });
                  });
                }
              });
            }

            // SUB-QUESTION OPTION TAGS
            if (Array.isArray(question.subquestions)) {
              question.subquestions.forEach((subQuestion) => {
                if (Array.isArray(subQuestion.options)) {
                  let subQuestionsOptions = [...subQuestion.options]; //to remove __ob__: Observer
                  subQuestionsOptions.forEach((option) => {
                    if (Array.isArray(option.answer_tags)) {
                      option.answer_tags.forEach((tag) => {
                        tags.push({
                          category: 'optionTag',
                          name: tag,
                          question_id: subQuestion.id,
                          question: subQuestion.question,
                          area_index: areaIndex,
                          group_index: groupIndex,
                          question_index: index + 1,
                          groupTitle: group.title,
                          areaTitle: area.title,
                          area_id: area.id,
                          group_id: group.id,
                        });
                      });
                    }
                  });
                }
              });
            }
          });
        });
      });

      const resultObject = {};
      tags.forEach(item => {
        const key = item.name + item.question_id;
        if (!resultObject[key]) {
          resultObject[key] = {
            name: item.name,
            question_id: item.question_id,
            question: item.question,
            questionIndex: item.question_index,
            groupIndex: item.area_index,
            areaIndex: item.area_index,
            groupTitle: item.groupTitle,
            areaTitle: item.areaTitle,
            areaId: item.area_id,
            groupId: item.group_id,
            'exclusionTags': false,
            'inclusionTags': false,
            'answerTagsTrue': false,
            'answerTagsFalse': false,
            'optionTag': false,
          };
        }
        if (item.category === 'exclusionTags') {
          resultObject[key]['exclusionTags'] = true;
        } else if (item.category === 'inclusionTags') {
          resultObject[key]['inclusionTags'] = true;
        } else if (item.category === 'optionTag') {
          resultObject[key]['optionTag'] = true;
        } else if (item.category === 'answerTagsTrue') {
          resultObject[key]['answerTagsTrue'] = true;
        } else if (item.category === 'answerTagsFalse') {
          resultObject[key]['answerTagsFalse'] = true;
        }
      });
      this.tagPool = [...new Set(tags.map(item => item.name))].concat([...new Set(this.supplierTags.map(item => item.name))]);
      return {
        tags: [...new Set(tags.map(item => item.name))].map((name) => ({name: name})),
        allTagsInfo: Object.values(resultObject),
        supplierTags: JSON.parse(JSON.stringify(this.supplierTags)),
      };
    },
    async getSupplierTags() {
      const supplierTags = [];
      const tags = await api.getSupplierTags(this.userRol.includes('globalAdmin'), this.client_id);
      await tags.forEach(tag => {
        supplierTags.push(JSON.parse(JSON.stringify({name: tag})));
      });
      this.supplierTags = JSON.parse(JSON.stringify(supplierTags));
    },
    closeAllPanels() {
      this.openedPanel = [];
    },
    openPanel(index) {
      this.openedPanel.push(index);
    },
    preAddGroup() {
      this.setGroup(this.currArea, this.currAreaIndex, this.currGroup, this.currGroupIndex);
      this.areas.forEach(area => {
        area.active = false;
      });
      this.areas[this.currAreaIndex].active = true;
    },
    preAddArea() {
      this.setArea(this.areas[this.areas.length - 1], (this.areas.length));
      this.areas.forEach(area => {
        area.active = false;
      });
    },
  },
};

</script>
