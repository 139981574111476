import Vue from 'vue'
import VueRouter from 'vue-router'
import Editor from '../Editor'
import axios from 'axios'
import cookies from 'js-cookie'
import Error from "../Error.vue";

Vue.use(VueRouter);

const ifAuthenticated = async (to, from, next) => {
    const host = (window.location.hostname === 'localhost') ? 'http://localhost:3005' : '';
    if (to.query.t && to.query.iv) {
        const res = await axios.post(host.concat('/auth/ticket'), {ticket: to.query.t, iv: to.query.iv});
        if (res.data?.token) {
            const token = res.data?.token;
            console.log({token});
            cookies.set('evt', token, {secure: true, sameSite: 'lax'});
            const url = new URL(window.location.href);
            url.searchParams.delete('t');
            url.searchParams.delete('iv');
            window.location.href = url.href;
        }
    } else {
        let token = cookies.get('evt');
        if (token) {
            const res = await axios.post(host.concat('/auth/token'), {token});
            if (res.data?.user_id) {
                to.params.userRol = res.data?.rol;
                to.params.userEnv = res.data?.env;
                next();
            } else {
                next('/error/auth');
            }
        }
    }
}

const routes = [
    {
        path: '/error/auth',
        name: 'error',
        component: Error,
    },
    {
        path: '/:init_id?',
        name: 'editor',
        component: Editor,
        props: true,
        beforeEnter: ifAuthenticated
    },
    {
        path: '/:copy_id/:set_client_id',
        name: 'edit',
        component: Editor,
        props: true,
        beforeEnter: ifAuthenticated
    },
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router
