<template>
    <div id="error">
        <div v-if="isInvalid">
            Error: Invalid link
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Error',
        data: () => ({
            isInvalid: true,
        }),
    }
</script>

<style scoped>

</style>