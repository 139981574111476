<template>
    <v-card :loading="isLoading">
        <v-card-title>{{ $t('translation') }}</v-card-title>
        <v-card-text>
            <v-textarea v-model="source_text" rows="3" outlined hide-details :label="$t('source_text')"
                        class="mb-2"></v-textarea>
            <v-text-field v-if="source_options.length > 0" v-model="source_options_string" readonly outlined
                          hide-details class="mb-2"
                          :label="$t('source_text')"></v-text-field>
            <div class="d-flex justify-space-between align-end mb-6">
                <v-select v-model="target" :items="languages" hide-details :label="$t('language')">
                </v-select>
                <v-btn color="info" @click.stop="translate" class="ml-2">
                    <v-icon>{{ icons.mdiGoogleTranslate }}</v-icon>
                    {{ $t('button_translate') }}
                </v-btn>
            </div>
            <v-textarea v-if="translated_text" rows="3" v-model="translated_text" outlined hide-details class="mb-2"
                        :label="$t('translation')"></v-textarea>
            <v-text-field v-if="translated_options.length > 0" v-model="translated_options_string" outlined hide-details
                          class="mb-2" readonly
                          :label="$t('translation')"></v-text-field>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="info" text @click.stop="$emit('close')">{{ $t('button_close') }}
            </v-btn>
            <v-btn color="info" v-if="translated_text" v-clipboard="translated_text" @success="handleCopySuccess"
                   @error="handleCopyError" text>{{ $t('button_copy_to_clipboard') }}
            </v-btn>
            <v-btn color="info" v-if="translated_text" @click.stop="accept" text>{{ $t('button_accept') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import api from '../inside-api'
    import {
        mdiGoogleTranslate
    } from '@mdi/js';

    export default {
        name: "TranslationTool",
        created() {
            this.source_text = this.question.question;
            if (this.question.options && this.question.options.length > 0) {
                for (const option of this.question.options) {
                    this.source_options.push(option.title);
                }
            }
            this.target = this.saqLang;
        },
        computed: {
            source_options_string() {
                if (this.source_options.length > 0) {
                    return this.source_options.join(', ');
                } else {
                    return '';
                }
            },
            translated_options_string() {
                if (this.translated_options.length > 0) {
                    return this.translated_options.join(', ');
                } else {
                    return '';
                }
            },
        },
        props: {
            question: Object,
            languages: Array,
            saqLang: String,
        },
        data: () => ({
            isLoading: false,
            source_text: '',
            source_options: [],
            translated_text: '',
            translated_options: [],
            target: 'en',
            icons: {
                mdiGoogleTranslate,
            }
        }),
        methods: {
            accept() {
                this.question.question = this.translated_text;
                if (this.translated_options.length > 0 && this.translated_options.length === this.question.options.length) {
                    for (let idx = 0; idx < this.question.options.length; idx += 1) {
                        this.question.options[idx].title = this.translated_options[idx];
                    }
                }
                this.$emit('close');
            },
            async translate() {
                try {
                    this.isLoading = true;
                    let translation = await api.translate(this.source_text, this.target);
                    if (Array.isArray(translation)) {
                        this.translated_text = translation[0];
                    } else {
                        this.translated_text = translation;
                    }
                    if (this.source_options.length > 0) {
                        this.translated_options = await api.translate(this.source_options, this.target);
                    }
                    this.isLoading = false;
                } catch {
                    alert('Translation error');
                    this.isLoading = false;
                }
            },
            handleCopySuccess() {
                alert('Copied to clipboard');
                this.$emit('close');
            },
            handleCopyError() {
                alert('Failed to copy to clipboard');
            },
        }
    }
</script>

<style scoped>

</style>