<template>
  <div class="pb-4">
    <!--SNACKBAR-->
    <v-snackbar
        v-model="snackbar"
        :timeout="timeout">
      {{ snackbarMessage }}
    </v-snackbar>

    <!--DIALOG-->
    <v-dialog v-model="dialogJson" width="800" max-width="800">
      <v-card>
        <v-card-title>{{ $t('edit_json') }}</v-card-title>
        <v-card-text>
          <vue-json-editor
              v-model="editorJson" :show-btns="false"
              :expanded-on-start="false"
              mode="tree"></vue-json-editor>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="dialogJson = false">{{ $t('button_close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="saveJson">{{ $t('button_save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG INGESTION-->
    <v-dialog v-model="dialogIngestion" max-width="600" scrollable>
      <v-card>
        <v-card-title>{{ $t('ingestion_settings') }}</v-card-title>
        <v-card-text>
          <ingestion-settings
              question-mode
              :saq-type="saqType"
              :variables="variables"
              :ingestion-settings="question.ingestion"
              @save="setIngestionSettings"
          ></ingestion-settings>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogIngestion = false">{{ $t('button_close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG-->
    <v-dialog v-model="dialogExplanation" max-width="600">
      <v-card>
        <v-card-title>{{ $t('explanation') }}</v-card-title>
        <v-card-text>
          <v-textarea v-model.trim="question.explanation"
                      outlined
                      dense
                      :label="$t('explanation')"
                      rows="4"
                      hide-details
                      auto-grow
                      @change="$emit('changed')"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogExplanation = false">{{ $t('button_close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG-->
    <v-dialog v-model="dialogRequestTemplate" max-width="600">
      <v-card>
        <v-card-title>{{ $t('request_template') }}</v-card-title>
        <v-card-text>
          <v-textarea v-model.trim="question.request_template"
                      outlined
                      dense
                      :label="$t('request_template')"
                      rows="4"
                      hide-details
                      auto-grow
                      @change="$emit('changed')"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogRequestTemplate = false">{{ $t('button_close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG-->
    <v-dialog v-model="dialogTags" max-width="600">
      <v-card>
        <v-card-title>{{ $t('inclusion_exclution_tags') }}</v-card-title>
        <v-card-text>
          <v-combobox v-model="question.inclusion_tags"
                      :prepend-icon="icons.mdiTagPlus"
                      :items="tagPool"
                      :search-input.sync="searchInclusion"
                      hide-selected
                      :label="$t('inclusion_tags')"
                      multiple
                      deletable-chips
                      chips
                      @change="$emit('changed')">
          </v-combobox>
          <v-combobox v-model="question.exclusion_tags"
                      :prepend-icon="icons.mdiTagOff"
                      :items="tagPool"
                      :search-input.sync="searchExclusion"
                      hide-selected
                      :label="$t('exclusion_tags')"
                      multiple
                      deletable-chips
                      chips
                      @change="$emit('changed')">
          </v-combobox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogTags = false">{{ $t('button_close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG-->
    <v-dialog v-model="dialogTranslate" max-width="800">
      <translation-tool :question="question" :languages="languages" :saq-lang="lang"
                        v-on:close="acceptTranslation"></translation-tool>
    </v-dialog>

    <!--DIALOG-->
    <v-dialog v-model="dialogBulkOptions" max-width="600">
      <v-card>
        <v-card-title>{{ $t('bulk_add_options') }}</v-card-title>
        <v-card-subtitle>{{ $t('bulk_add_instructions') }}</v-card-subtitle>
        <v-card-text>
          <v-textarea v-model.trim="bulkOptions"
                      outlined
                      dense
                      :label="$t('options')"
                      rows="8"
                      hide-details
                      auto-grow></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="dialogBulkOptions = false">{{ $t('button_close') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="info" text @click="bulkAddOptions">{{ $t('add') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG-->
    <v-dialog v-model="dialogInfo" max-width="600">
      <v-card>
        <v-card-title>{{ $t('risk_indication_text') }}</v-card-title>
        <v-card-text>
          <v-textarea v-model.trim="question.info"
                      outlined
                      dense
                      rows="4"
                      hide-details
                      auto-grow
                      @change="$emit('changed')"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogInfo = false">{{ $t('button_close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG-->
    <v-dialog v-model="dialogDelete" max-width="500">
      <v-card>
        <v-card-title>{{ $t('confirm_generic') }}</v-card-title>
        <v-card-actions>
          <v-btn color="secondary" text @click="dialogDelete = false">{{ $t('button_cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="deleteQuestion">{{ $t('button_yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG-->
    <v-dialog v-model="dialogAnswerConnection" max-width="600">
      <v-card>
        <v-card-title>{{ $t('answer_connection') }}</v-card-title>
        <v-card-text>
          <v-select v-model="question.supplier_data"
                    :items="spFields"
                    item-text="label"
                    item-value="field_number"
                    :prepend-icon="icons.mdiLinkVariant"
                    :label="$t('connected_to')"
                    @change="$emit('changed')">
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogAnswerConnection = false">{{ $t('button_close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row no-gutters :key="componentKey">
      <v-col cols="6" class="pb-4 d-flex">
        <v-select v-model="question.type"
                  :items="questionTypes"
                  item-value="type"
                  item-text="label"
                  outlined
                  hide-details
                  dense
                  label="Question type"
                  class="mr-1"
                  @change.capture="setType"></v-select>
        <v-text-field v-model.trim="question.ref"
                      hide-details
                      outlined
                      dense
                      label="Reference ID"></v-text-field>
      </v-col>
      <v-col cols="6" class="text-end">
        <!--RISK INDICATION TEXT-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="dialogInfo = true">
              <v-icon v-if="question.info" color="info">{{ icons.mdiInformation }}</v-icon>
              <v-icon v-else>{{ icons.mdiInformation }}</v-icon>
            </v-btn>
          </template>
          <span> {{ $t('risk_indication_text') }}</span>
        </v-tooltip>
        <!--CAR TEMPLATE-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="dialogRequestTemplate = true">
              <v-icon v-if="question.request_template" color="info">{{ icons.mdiRedoVariant }}</v-icon>
              <v-icon v-else>{{ icons.mdiRedoVariant }}</v-icon>
            </v-btn>
          </template>
          <span> {{ $t('CAR_template') }}</span>
        </v-tooltip>
        <!--EXPLANATION-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="dialogExplanation = true">
              <v-icon v-if="question.explanation" color="info">{{ icons.mdiExclamationThick }}</v-icon>
              <v-icon v-else>{{ icons.mdiExclamationThick }}</v-icon>
            </v-btn>
          </template>
          <span> {{ $t('explanation') }}</span>
        </v-tooltip>
        <!--INGESTION-->
        <v-tooltip bottom v-if="question.type === 'customtable'">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="dialogIngestion = true">
              <v-icon>{{ icons.mdiDatabaseImportOutline }}</v-icon>
            </v-btn>
          </template>
          <span> {{ $t('ingestion_settings') }}</span>
        </v-tooltip>
        <!--TRANSLATE-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="dialogTranslate = true">
              <v-icon>{{ icons.mdiGoogleTranslate }}</v-icon>
            </v-btn>
          </template>
          <span> {{ $t('translate') }}</span>
        </v-tooltip>
        <!--DELETE-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="dialogDelete = true">
              <v-icon>{{ icons.mdiDelete }}</v-icon>
            </v-btn>
          </template>
          <span> {{ $t('delete') }}</span>
        </v-tooltip>
        <!--JSON-->
        <v-tooltip bottom v-if="userIsGlobalAdmin">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="openJsonEditor">
              <v-icon>{{ icons.mdiCodeJson }}</v-icon>
            </v-btn>
          </template>
          <span> {{ $t('json') }}</span>
        </v-tooltip>
        <!--COPY QUESTION-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="copyQuestion">
              <v-icon>{{ icons.mdiContentCopy }}</v-icon>
            </v-btn>
          </template>
          <span> {{ $t('copy_question') }}</span>
        </v-tooltip>
        <!--ADD OPTIONS-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="dialogBulkOptions = true"
                   v-if="question.type === 'sc' || question.type === 'mc'">
              <v-icon>{{ icons.mdiFormatListChecks }}</v-icon>
            </v-btn>
          </template>
          <span> {{ $t('add_options') }}</span>
        </v-tooltip>
        <!--SUPPLIER DATA LINK-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on"
                   v-if="question.type !== 'files' && question.type !== 'certificate' && question.type !== 'certificatetable'"
                   icon @click.stop="dialogAnswerConnection = true">
              <v-icon v-if="question.supplier_data" color="info">{{ icons.mdiLinkVariant }}</v-icon>
              <v-icon v-if="!question.supplier_data">{{ icons.mdiLinkVariant }}</v-icon>
            </v-btn>
          </template>
          <span> {{ $t('supplier_data_link') }}</span>
        </v-tooltip>
        <!--QUESTION TAGS-->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" @click.stop="dialogTags = true">
              <v-icon v-if="hasTags" color="info">{{ icons.mdiTag }}</v-icon>
              <v-icon v-else>{{ icons.mdiTag }}</v-icon>
            </v-btn>
          </template>
          <span> {{ $t('inclusion_exclution_tags') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="9" class="pb-4 d-flex">
        <v-text-field v-model.trim="question.tag"
                      hide-details
                      outlined
                      dense
                      class="mr-1"
                      :label="$t('content_tag')"></v-text-field>
        <v-text-field v-model.trim="question.variable"
                      v-if="isVariableType"
                      hide-details
                      outlined
                      dense
                      class="mr-1"
                      :label="$t('variable')"></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="pb-4">
        <v-textarea v-model.trim="question.question"
                    outlined
                    dense
                    :label="$t('question')"
                    rows="2"
                    hide-details
                    auto-grow
                    @change="$emit('changed')"></v-textarea>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="question.type === 'files'">
      <v-col cols="6" class="py-0">
        <v-switch
            v-model="question.single"
            :label="$t('single_file')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="rated"
            :label="$t('rated')"
            hide-details
            @change="toggleRated"
        ></v-switch>
        <v-switch
            v-model="enforcedFileUpload"
            :label="$t('enforced_file_upload')"
            hide-details
            @change="enforcedToggle"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="question.type === 'certificate'">
      <v-col cols="6" class="py-0">
        <v-switch
            v-model="enforcedFileUpload"
            :label="$t('enforced_file_upload')"
            hide-details
            @change="enforcedToggle"
        ></v-switch>
        <v-switch
            v-model="question.include.certificate_type"
            :label="$t('include_certificate_type')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.expiration_date"
            :label="$t('include_expiration_date')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.require_expiration_date"
            :label="$t('require_expiration_date')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.certificate_number"
            :label="$t('include_certificate_number')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.location"
            :label="$t('include_location')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-text-field
            v-model="question.certificate_type"
            outlined
            dense
            :label="$t('certificate_type')"
            :hint="$t('certificate_type_hint')"
            @change="$emit('changed')"></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="question.type === 'ownertable'">
      <v-col class="py-0">
        <v-switch
            v-model="question.include.nationality"
            :label="$t('include_nationality')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.ownership"
            :label="$t('include_ownership')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.incorporation_country"
            :label="$t('include_incorporation_country')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.date_acquisition"
            :label="$t('include_date_of_acquisition')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.date_birth"
            :label="$t('include_date_of_birth')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.address"
            :label="$t('include_address')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.comment"
            :label="$t('include_comment')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="question.type === 'unittable'">
      <v-col class="py-0">
        <v-switch
            v-model="question.include.mapmarker"
            :label="$t('include_mapmarker')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="question.type === 'companytable'">
      <v-col class="py-0">
        <v-switch
            v-model="question.include.crn"
            :label="$t('include_crn')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.relationship"
            :label="$t('include_relationship')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.comment"
            :label="$t('include_comment')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="question.type === 'suppliers'">
      <v-col class="py-0">
        <v-switch
            v-model="question.include.crn"
            :label="$t('include_crn')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.product_service"
            :label="$t('include_product_service')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.nace"
            :label="$t('include_nace')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.comment"
            :label="$t('include_comment')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="question.type === 'principaltable'">
      <v-col class="py-0">
        <v-switch
            v-model="question.include.nationality"
            :label="$t('include_nationality')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.ownership"
            :label="$t('include_ownership')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.date_appointment"
            :label="$t('include_date_of_appointment')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.date_birth"
            :label="$t('include_date_of_birth')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.comment"
            :label="$t('include_comment')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="question.type === 'certificatetable'">
      <v-col class="py-0">
        <v-switch
            @click="enforcedToggle"
            v-model="enforcedFileUpload"
            :label="$t('enforced_file_upload')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.certificate_type"
            :label="$t('include_certificate_type')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.expiration_date"
            :label="$t('include_expiration_date')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.require_expiration_date"
            :label="$t('require_expiration_date')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.certificate_number"
            :label="$t('include_certificate_number')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
        <v-switch
            v-model="question.include.location"
            :label="$t('include_location')"
            hide-details
            @change="$emit('changed')"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="question.type === 'slider'">
      <v-col class="py-0">
        <v-text-field type="number"
                      v-model="question.min"
                      :label="$t('min')"
                      @change="$emit('changed')"></v-text-field>
      </v-col>
      <v-col class="py-0">
        <v-text-field type="number"
                      v-model="question.max"
                      :label="$t('max')"
                      @change="$emit('changed')"></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="question.type === 'number' || question.type === 'slider'">
      <v-col class="py-0">
        <v-switch
            v-model="rated"
            :label="$t('rated')"
            @change="toggleRated"
        ></v-switch>
      </v-col>
      <v-col class="py-0">
        <v-switch
            v-model="question.percent"
            :label="$t('percent')"
            @change="toggleRated"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="question.type === 'select'">
      <v-col class="py-0">
        <v-switch
            v-model="question.multiselect"
            :label="$t('multiselect')"
            @change="$emit('changed')"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row v-if="(question.type === 'number' || question.type === 'slider') && rated">
      <v-col cols="6" class="py-0">
        <v-text-field type="number"
                      v-model="question.lower_limit"
                      :label="$t('lower_limit')"
                      :hint="$t('limit_hint')"
                      @change="$emit('changed')"></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-select v-model="question.lt_result" :items="ratings" item-text="label" item-value="result"
                  :label="$t('rating_if_under')" @change="$emit('changed')">
          <template v-slot:append>
            <v-icon
                :color="question.lt_result > 0 || question.lt_result === 0 ? ratingColors[question.lt_result] : 'rgba(0, 0, 0, .2)'">
              {{ icons.mdiCircle }}
            </v-icon>
          </template>
        </v-select>
        <v-combobox
            v-model="question.answer_tags_under"
            :items="tagPool"
            hide-selected
            :label="$t('answer_tags')"
            multiple
            deletable-chips
            persistent-hint
            small-chips>
        </v-combobox>
      </v-col>
    </v-row>
    <v-row v-if="(question.type === 'number' || question.type === 'slider') && rated">
      <v-col cols="6" class="py-0">
        <v-text-field type="number"
                      v-model="question.upper_limit"
                      :label="$t('upper_limit')"
                      :hint="$t('limit_hint')" @change="$emit('changed')"></v-text-field>
      </v-col>
      <v-col cols="6" class="py-0">
        <v-select v-model="question.gt_result" :items="ratings" item-text="label" item-value="result"
                  :label="$t('rating_if_over')" @change="$emit('changed')">
          <template v-slot:append>
            <v-icon
                :color="question.gt_result > 0 || question.gt_result === 0 ? ratingColors[question.gt_result] : 'rgba(0, 0, 0, .2)'">
              {{ icons.mdiCircle }}
            </v-icon>
          </template>
        </v-select>
        <v-combobox
            v-model="question.answer_tags_over"
            :items="tagPool"
            hide-selected
            :label="$t('answer_tags')"
            multiple
            deletable-chips
            persistent-hint
            small-chips>
        </v-combobox>
      </v-col>
    </v-row>
    <v-row v-if="question.type === 'number'" no-gutters>
      <v-col>
        <v-select v-model="question.comparison_fields"
                  :items="comparisonFields.filter(cf => cf.id !== question.id)"
                  item-text="question"
                  item-value="id"
                  multiple small-chips
                  :hint="$t('add_results_from_hint')"
                  persistent-hint
                  :label="$t('add_results_from')"
                  @change="$emit('changed')">
        </v-select>
      </v-col>
    </v-row>
    <v-row v-if="question.type === 'bool'">
      <v-col cols="4" class="py-0">
        <v-select v-model="question.result_true" :items="ratings" item-text="label" item-value="result"
                  :label="$t('rating').concat(', ', $t('yes'))" @change="$emit('refresh')">
          <template v-slot:append>
            <v-icon
                :color="question.result_true > 0 || question.result_true === 0 ? ratingColors[question.result_true] : 'rgba(0, 0, 0, .2)'">
              {{ icons.mdiCircle }}
            </v-icon>
          </template>
        </v-select>
      </v-col>
      <v-col cols="4" class="py-0" v-if="!isSub">
        <v-checkbox v-model="question.require_subs_true"
                    :label="$t('require_subs')" @change="$emit('changed')"></v-checkbox>
      </v-col>
      <v-col cols="4" class="py-0">
        <v-combobox
            v-model="question.answer_tags_true"
            :items="tagPool"
            hide-selected
            :label="$t('answer_tags')"
            multiple
            deletable-chips
            persistent-hint
            small-chips>
        </v-combobox>
      </v-col>
    </v-row>
    <v-row v-if="question.type === 'bool'">
      <v-col cols="4" class="py-0">
        <v-select v-model="question.result_false" :items="ratings" item-text="label" item-value="result"
                  :label="$t('rating').concat(', ', $t('no'))" @change="$emit('changed')">
          <template v-slot:append>
            <v-icon
                :color="question.result_false > 0 || question.result_false === 0 ? ratingColors[question.result_false] : 'rgba(0, 0, 0, .2)'">
              {{ icons.mdiCircle }}
            </v-icon>
          </template>
        </v-select>
      </v-col>
      <v-col cols="4" class="py-0" v-if="!isSub">
        <v-checkbox v-model="question.require_subs_false"
                    :label="$t('require_subs')" @change="$emit('changed')"></v-checkbox>
      </v-col>
      <v-col cols="4" class="py-0">
        <v-combobox
            v-model="question.answer_tags_false"
            :items="tagPool"
            hide-selected
            :label="$t('answer_tags')"
            multiple
            deletable-chips
            persistent-hint
            small-chips>
        </v-combobox>
      </v-col>
    </v-row>
    <v-row v-if="question.type === 'sc' || question.type === 'mc' || question.type === 'select'" no-gutters>
      <v-col>
        <option-table :question="question" :tag-pool="tagPool" :is-sub="isSub" :key="optionsKey"
                      v-on:changed="$emit('changed')"></option-table>
      </v-col>
    </v-row>
    <v-row v-if="question.type === 'customtable'" no-gutters>
      <v-col>
        <v-select v-model="question.max_columns" :items="[2, 3]" :label="$t('n_columns')" dense outlined
                  style="max-width: 160px" hide-details class="mb-4"></v-select>
        <column-table :question="question" :key="columnsKey"
                      v-on:changed="$emit('changed')"></column-table>
      </v-col>
    </v-row>
    <v-row
        v-if="['country', 'countries', 'principaltable', 'ownertable', 'companytable', 'suppliers'].includes(question.type)">
      <v-col>
        <country-group-table :question="question" :tag-pool="tagPool" :is-sub="isSub"
                             v-on:changed="$emit('changed')"></country-group-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OptionTable from './OptionTable';
import ColumnTable from './ColumnTable';
import TranslationTool from './TranslationTool';
import CountryGroupTable from './CountryGroupTable';
import {
  mdiDelete,
  mdiTag,
  mdiTagPlus,
  mdiTagOff,
  mdiTagOutline,
  mdiCircle,
  mdiLinkVariant,
  mdiGoogleTranslate,
  mdiInformation,
  mdiFormatListChecks,
  mdiExclamationThick,
  mdiCodeJson,
  mdiContentCopy,
  mdiRedoVariant,
  mdiDatabaseImportOutline,
} from '@mdi/js';
import Short from '../shortuid';
import _cloneDeep from 'lodash/cloneDeep';
import VueJsonEditor from 'vue-json-editor';
import IngestionSettings from './IngestionSettings.vue';

export default {
  name: 'Question',
  props: {
    question: Object,
    index: Number,
    tagPool: Array,
    variables: Array,
    saqType: String,
    userRol: Array,
    isSub: Boolean,
    languages: Array,
    lang: String,
    comparisonFields: Array,
  },
  created() {
    this.setType();
    this.rated = this.question.rated;
    this.userIsGlobalAdmin = this.userRol.includes('globalAdmin');
    this.enforcedFileUpload = !!this.question.enforced;
  },
  components: {
    IngestionSettings,
    OptionTable,
    ColumnTable,
    TranslationTool,
    CountryGroupTable,
    VueJsonEditor,
  },
  computed: {
    isVariableType() {
      const typeItem = this.questionTypes.find(i => i.type === this.question.type);
      if (typeItem.type === 'files') {
        return !!typeItem.variable && !!this.question.single;
      }
      return !!typeItem.variable;
    },
    /**
     * Checks if the question has any tags.
     *
     * @returns {boolean} True if the question has tags, false otherwise.
     */
    hasTags() {
      let tags = false;
      if (this.question.inclusion_tags && this.question.inclusion_tags.length > 0) {
        tags = true;
      }
      if (this.question.exclusion_tags && this.question.exclusion_tags.length > 0) {
        tags = true;
      }
      return tags;
    },
  },
  data: () => ({
    enforcedFileUpload: false,
    dialogAnswerConnection: false,
    dialogTags: false,
    userIsGlobalAdmin: false,
    dialogIngestion: false,
    dialogDelete: false,
    dialogInfo: false,
    dialogExplanation: false,
    dialogRequestTemplate: false,
    dialogTranslate: false,
    dialogBulkOptions: false,
    dialogJson: false,
    editorJson: {},
    searchInclusion: null,
    searchExclusion: null,
    componentKey: 0,
    rated: false,
    bulkOptions: '',
    optionsKey: 0,
    columnsKey: 0,
    spFields: [
      {field: null, label: 'No connection', field_number: null},
      {field: 'website', label: 'Website', field_number: 1},
      {field: 'company', label: 'Company', field_number: 2},
      {field: 'email', label: 'Email', field_number: 3},
      {field: 'phone', label: 'Phone', field_number: 4},
      {field: 'address', label: 'Address', field_number: 5},
      {field: 'city', label: 'City', field_number: 6},
      {field: 'zip', label: 'Zip', field_number: 7},
      {field: 'country', label: 'Country', field_number: 8},
      {field: 'contact_name', label: 'Contact name', field_number: 9},
      {field: 'contact_position', label: 'Contact position', field_number: 10},
      {field: 'contact_language', label: 'Contact language', field_number: 11},
      {field: 'contact_email', label: 'Contact email', field_number: 12},
      {field: 'contact_phone', label: 'Contact phone', field_number: 13},
      {field: 'sector', label: 'Sector', field_number: 14},
      {field: 'operating_countries', label: 'Operating countries', field_number: 15},
      {field: 'turnover_usd', label: 'Turnover USD', field_number: 16},
      {field: 'no_employees', label: '# employees', field_number: 17},
      {field: 'no_suppliers', label: '# suppliers', field_number: 18},
      {field: 'no_customers', label: '# customers', field_number: 19},
      {field: 'units', label: 'Units', field_number: 20},
      {field: 'custom1', label: 'Custom 1', field_number: 91},
      {field: 'custom2', label: 'Custom 2', field_number: 92},
      {field: 'custom3', label: 'Custom 3', field_number: 93},
      {field: 'custom4', label: 'Custom 4', field_number: 94},
      {field: 'custom5', label: 'Custom 5', field_number: 95},
    ],
    ratings: [
      {
        label: 'Not rated',
        result: null,
      },
      {
        label: 'Green',
        result: 0,
      },
      {
        label: 'Yellow',
        result: 1,
      },
      {
        label: 'Red',
        result: 2,
      },
    ],
    ratingColors: ['success', 'warning', 'error'],
    questionTypes: [
      {
        type: 'text',
        label: 'Text',
        variable: true,
      },
      {
        type: 'longtext',
        label: 'Long text',
        variable: true,
      },
      {
        type: 'number',
        label: 'Number',
        variable: true,
      },
      {
        type: 'slider',
        label: 'Slider',
        variable: true,
      },
      {
        type: 'date',
        label: 'Date',
        variable: true,
      },
      {
        type: 'bool',
        label: 'Yes / No',
        variable: true,
      },
      {
        type: 'select',
        label: 'Select',
        variable: true,
      },
      {
        type: 'sc',
        label: 'Single choice',
        variable: true,
      },
      {
        type: 'mc',
        label: 'Multiple choice',
        variable: true,
      },
      {
        type: 'certificate',
        label: 'Certificate',
      },
      {
        type: 'certificatetable',
        label: 'Certificate table',
      },
      {
        type: 'files',
        label: 'File uploads',
        variable: true,
      },
      {
        type: 'sectors',
        label: 'Sector selector',
      },
      {
        type: 'nace',
        label: 'NACE code selector',
        variable: true,
      },
      {
        type: 'unittable',
        label: 'Unit table',
      },
      {
        type: 'principaltable',
        label: 'Principal table',
      },
      {
        type: 'ownertable',
        label: 'Owner table',
      },
      {
        type: 'customtable',
        label: 'Custom table',
      },
      {
        type: 'companytable',
        label: 'Company (group structure/affiliates) table',
      },
      {
        type: 'country',
        label: 'Country selector',
        variable: true,
      },
      {
        type: 'countries',
        label: 'Country selector (multiple)',
        variable: true,
      },
      {
        type: 'suppliers',
        label: 'Supplier table',
      },
    ],
    icons: {
      mdiDelete,
      mdiTag,
      mdiTagPlus,
      mdiTagOff,
      mdiTagOutline,
      mdiCircle,
      mdiLinkVariant,
      mdiGoogleTranslate,
      mdiInformation,
      mdiFormatListChecks,
      mdiExclamationThick,
      mdiCodeJson,
      mdiContentCopy,
      mdiRedoVariant,
      mdiDatabaseImportOutline,
    },
    timeout: 3000,
    snackbar: false,
    snackbarMessage: '',
  }),
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(JSON.stringify(this.question, null, 2)).catch(() => {
        console.error('Could not copy to clipboard');
      });
    },
    copyQuestion() {
      this.$emit('copy', this.question);
      this.copyToClipboard();
    },
    openJsonEditor() {
      this.editorJson = _cloneDeep(this.question);
      this.dialogJson = true;
    },
    saveJson() {
      try {
        this.dialogJson = false;
        let test = JSON.parse(JSON.stringify(this.editorJson));
        if (test.id) {
          this.$emit('question', {
            id: this.question.id,
            question: test,
          });
          this.$emit('changed');
        } else {
          alert('JSON error');
        }
      } catch {
        alert('JSON error');
      }
    },
    bulkAddOptions() {
      if (this.bulkOptions) {
        const options = this.bulkOptions.split('\n');
        if (!this.question.options) {
          this.question.options = [];
        }
        for (const option of options) {
          this.question.options.push({
            id: Short.generate(),
            title: option.trim(),
            result: 0,
            require_subs: false,
            answer_tags: [],
          });
        }
        this.$emit('changed');
        this.optionsKey += 1;
        this.dialogBulkOptions = false;
        this.bulkOptions = '';
      }
    },
    acceptTranslation() {
      this.dialogTranslate = false;
    },
    deleteQuestion() {
      this.dialogDelete = false;
      this.$emit('delete', this.question.id);
    },
    toggleRated() {
      this.question.rated = this.rated;
      this.$emit('changed');
    },
    setIngestionSettings(settings) {
      this.question.ingestion = settings;
    },
    setType() {
      if (this.question.type !== 'files' && !this.question.supplier_data) {
        this.question.supplier_data = null;
      }
      if (this.question.type === 'files') {
        this.question.single ??= false;
      }
      if (this.question.type === 'slider') {
        this.question.min ??= 0;
        this.question.max ??= 100;
        this.question.percent ??= true;
      }
      if (this.question.type === 'customtable') {
        this.question.max_columns ??= 2;
      }
      if (this.question.type === 'bool') {
        if (!this.question.text_true) {
          this.question.text_true = 'Yes';
        }
        if (!this.question.text_false) {
          this.question.text_false = 'No';
        }
        if (!this.question.result_true) {
          this.question.result_true = 0;
        }
        if (!this.question.result_false) {
          this.question.result_false = 0;
        }
        if (!this.question.require_subs_true) {
          this.question.require_subs_true = false;
        }
        if (!this.question.require_subs_false) {
          this.question.require_subs_false = false;
        }
        if (!this.question.answer_tags_true) {
          this.question.answer_tags_true = [];
        }
        if (!this.question.answer_tags_false) {
          this.question.answer_tags_false = [];
        }
      } else if (['country', 'countries'].includes(this.question.type)) {
        if (!this.question.country_groups) {
          this.question.country_groups = [];
        }
      } else if (this.question.type === 'number') {
        if (this.question.rated !== false) {
          this.question.rated = true;
        }
        if (!this.question.comparison_fields) {
          this.question.comparison_fields = [];
        }
        if (!this.question.gt_result) {
          this.question.gt_result = 0;
        }
        if (!this.question.lt_result) {
          this.question.lt_result = 0;
        }
        if (!this.question.lower_limit) {
          this.question.lower_limit = -1;
        }
        if (!this.question.upper_limit) {
          this.question.upper_limit = -1;
        }
      } else if (this.question.type === 'certificate') {
        if (!this.question.certificate_type) {
          this.question.certificate_type = '';
        }
        if (!this.question.require_expiration_date) {
          this.question.require_expiration_date = true;
        }
        if (!this.question.include) {
          this.question.include = {};
        }
      } else if (this.question.type === 'certificatetable') {
        if (!this.question.require_expiration_date) {
          this.question.require_expiration_date = true;
        }
        if (!this.question.include) {
          this.question.include = {};
        }
      } else if (this.question.type === 'principaltable') {
        if (!this.question.include) {
          this.question.include = {
            nationality: true,
          };
        }
        if (!this.question.country_groups) {
          this.question.country_groups = [];
        }
      } else if (this.question.type === 'ownertable') {
        if (!this.question.include) {
          this.question.include = {
            nationality: true,
          };
        }
        if (!this.question.country_groups) {
          this.question.country_groups = [];
        }
      } else if (this.question.type === 'companytable') {
        if (!this.question.include) {
          this.question.include = {
            crn: true,
            relationship: true,
          };
        }
        if (!this.question.country_groups) {
          this.question.country_groups = [];
        }
      } else if (this.question.type === 'unittable') {
        if (!this.question.include) {
          this.question.include = {};
        }
      } else if (this.question.type === 'suppliers') {
        if (!this.question.include) {
          this.question.include = {
            nace: true,
            crn: true,
          };
        }
        if (!this.question.country_groups) {
          this.question.country_groups = [];
        }
      } else if (this.question.type === 'mc' || this.question.type === 'sc') {
        if (!this.question.options) {
          this.question.options = [];
        }
      }
      if (this.question.type === 'bool' || this.question.options) {
        this.question.statistics = true;
      }
      if (this.question.type === 'files' && this.question.rated !== false) {
        this.question.rated = true;
        this.question.single = false;
      }
    },
    enforcedToggle() {
      if (this.question.type === 'files' || this.question.type === 'certificate' || this.question.type === 'certificatetable') {
        this.question.enforced = this.enforcedFileUpload;
        this.$emit('changed');
      }
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0;
}
</style>
