import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from '../i18n';
import '../sass/overrides.sass';
import colorTokens from '../assets/tokens/stratsys-palette.json';

Vue.use(Vuetify);

const theme = {
  // Vuetify built-ins
  primary: colorTokens['brand-core-500'],
  secondary: colorTokens['brand-neutral-400'],
  accent: colorTokens['ai-accent-500'],
  info: colorTokens['ai-accent-500'],
  warning: colorTokens['purpose-risks-400'],
  error: colorTokens['purpose-risks-600'],
  success: colorTokens['purpose-risks-100'],

  // Basics
  background: colorTokens['brand-neutral-100'],
  text: colorTokens['brand-neutral-600'],

  // Neutrals
  tertiary: colorTokens['brand-neutral-550'],
  muted: colorTokens['brand-neutral-400'],
  silver: colorTokens['brand-neutral-200'],
  cloudy: colorTokens['brand-neutral-150'],
  sky: colorTokens['brand-neutral-100'],

  // Grays
  gainsboro: colorTokens['gray-250'],
  dimgray: colorTokens['gray-550'],

  // Signals
  green: colorTokens['purpose-risks-100'],
  yellow: colorTokens['purpose-risks-400'],
  red: colorTokens['purpose-risks-600'],
  danger: colorTokens['purpose-risks-600'],
  critical: colorTokens['purpose-risks-700'],
  blue: colorTokens['ai-accent-500'],
  lightblue: colorTokens['ai-accent-300'],
};

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    options: {customProperties: true},
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
