<template>
  <section>
    <v-snackbar v-model="snack.show" :timeout="snack.timeout">
      {{ snack.message }}
    </v-snackbar>
    <v-tabs v-model="tab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#tab-1">
        {{ $t('saq_tags') }}
      </v-tab>
      <v-tab href="#tab-2">
        {{ $t('supplier_tags') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <!--TAGS OVERVIEW-->
      <v-tab-item :value="'tab-1'">
        <v-data-table
            :headers="tagHeaders"
            :footer-props="footerOptions"
            :items-per-page="50"
            :items="tagPool.tags"
            item-key="name"
            :expanded.sync="expanded"
            :search="search">
          <template v-slot:top>
            <v-toolbar flat>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-btn icon @click="expandAll">
                    <v-icon v-on="on" class="mr-4">{{ icons.mdiTagMultipleOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>Open/Close all tags</span>
              </v-tooltip>
              <v-text-field clearable v-model="search" label="Search" single-line hide-details></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item, expand, isExpanded }">
            <tr @click="expand(!isExpanded)" style="cursor: pointer; background-color: rgba(245,245,245,0.8);">
              <td style="width: 70%" v-for="(field,index) in Object.keys(item)" :key="index + item.name">
                <v-icon class="mr-2" @click="expand(!isExpanded)">{{ icons.mdiTagOutline }}</v-icon>
                {{ item[field] }}
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="ml-1" size="15" @click="copyToClipboard(item[field]); expand(!isExpanded)">
                      {{ icons.mdiContentCopy }}
                    </v-icon>
                  </template>
                  <span>Copy</span>
                </v-tooltip>
              </td>

              <td style=" text-align: center">
                {{ tagPool.allTagsInfo.filter(x => x.name === item.name).length }}
              </td>
              <td style="text-align: center">
                {{ countUniqueGroupTitles(tagPool.allTagsInfo.filter(x => x.name === item.name)) }}
              </td>
              <td style=" text-align: center">
                {{ countUniqueAreasTitles(tagPool.allTagsInfo.filter(x => x.name === item.name)) }}
              </td>
              <td v-if="!isExpanded">
                <v-icon>{{ icons.mdiArrowDownDropCircleOutline }}</v-icon>
              </td>
              <td v-if="isExpanded">
                <v-icon>{{ icons.mdiArrowUpDropCircleOutline }}</v-icon>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers,item }">
            <td :colspan="headers.length">
              <v-data-table
                  :hide-default-footer="true"
                  dense
                  :items-per-page="-1"
                  :headers="expandedHeaders"
                  :items="tagPool.allTagsInfo.filter(x => x.name === item.name)"
                  :search="search">
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="truncate">
                      <v-tooltip right>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" class="mr-4" @click="goToQuestion(item)">{{ icons.mdiOpenInNew }}</v-icon>
                        </template>
                        <span>Go to tag in question </span>
                      </v-tooltip>
                      {{ item.question }}
                    </td>
                    <td style="text-align: center">
                      {{ (item.answerTagsTrue || item.answerTagsFalse || item.optionTag) ? 'Yes' : '' }}
                    </td>
                    <td style="text-align: center">{{ item.inclusionTags ? 'Yes' : '' }}</td>
                    <td style="text-align: center">{{ item.exclusionTags ? 'Yes' : '' }}</td>
                  </tr>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-tab-item>
      <!--AVAILABLE TAGS OVERVIEW-->
      <v-tab-item :value="'tab-2'">
        <v-data-table
            :headers="[{ text:'Available supplier tags', value: 'name',align: 'start',sortable: false}]"
            :items="tagPool.supplierTags"
            :items-per-page="50"
            :footer-props="footerOptions"
            class="elevation-1"
            :search="search">

          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field clearable v-model="search" append-icon="mdi-magnify" label="Search" single-line
                            hide-details></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item}">
            <tr style="background-color: rgba(245,245,245,0.8);">
              <td style="width: 100%" v-for="(field,index) in Object.keys(item)" :key="index + item.name">
                <v-icon class="mr-2">{{ icons.mdiTagOutline }}</v-icon>
                {{ item[field] }}
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="ml-2" size="15" @click="copyToClipboard(item[field])">
                      {{ icons.mdiContentCopy }}
                    </v-icon>
                  </template>
                  <span>copy</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </section>
</template>

<script>
import {
  mdiArrowDownDropCircleOutline,
  mdiArrowUpDropCircleOutline,
  mdiDotsHorizontal, mdiOpenInNew, mdiRefresh, mdiTagArrowUpOutline, mdiTagArrowDownOutline,
  mdiTagOutline, mdiTagMultipleOutline, mdiContentCopy,
} from '@mdi/js';

export default {
  name: 'TagsOverview',
  props: {
    userRol: Array,
    tagPool: Object,
    client_id: String,
  },
  watch: {
    tagPool: {
      deep: true,
      handler(val) {
        this.tagPool = val;
      },
    },
  },
  data: () => ({
    snack: {show: false, message: '', timeout: 5000},
    expanded: [],
    supplierTags: [],
    search: '',
    footerOptions: {
      'items-per-page-options': [50, 100, 250, 500, -1],
    },
    tab: null,
    icons: {
      mdiTagOutline,
      mdiDotsHorizontal,
      mdiContentCopy,
      mdiRefresh,
      mdiOpenInNew,
      mdiTagArrowUpOutline,
      mdiTagArrowDownOutline,
      mdiTagMultipleOutline,
      mdiArrowDownDropCircleOutline,
      mdiArrowUpDropCircleOutline,
    },
    tagHeaders: [
      {text: 'Tags currently in use', align: 'start', value: 'name'},
      {text: 'Questions', value: 'usage', align: 'center', sortable: false},
      {text: 'Groups', value: 'answers', align: 'center', sortable: false},
      {text: 'Areas', value: 'inclusions', align: 'center', sortable: false},
      {text: '', value: 'data-table-expand'},
    ],
    expandedHeaders: [
      {text: 'Question', align: 'start', sortable: false, value: 'question', width: '70%'},
      {text: 'Answer', value: 'answers', width: '10%', align: 'center', sortable: false},
      {text: 'Inclusion', value: 'inclusions', width: '10%', align: 'center', sortable: false},
      {text: 'Exclusion', value: 'exclusions', width: '10%', align: 'center', sortable: false},
    ],
  }),
  methods: {
    countUniqueGroupTitles(data) {
      const groupTitles = data.map(item => item.groupTitle);
      const uniqueGroupTitles = [...new Set(groupTitles)];
      return uniqueGroupTitles.length;
    },
    countUniqueAreasTitles(data) {
      const areaTitles = data.map(item => item.areaTitle);
      const uniqueAreaTitles = [...new Set(areaTitles)];
      return uniqueAreaTitles.length;
    },
    expandAll() {
      if (this.expanded.length === 0) {
        const unique = [...new Set(this.tagPool.allTagsInfo.map(item => item.name))];
        unique.forEach(tag => {
          this.expanded.push({name: tag});
        });
      } else {
        this.expanded = [];
      }
    },
    goToQuestion(row) {
      this.$emit('go-to-tag-question', row);
      this.expanded = [];
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.showSnackMessage('Tag name copied');
      }).catch(() => {
        this.showSnackMessage('error try copy tag name');
      });
    },
    showSnackMessage(message) {
      this.snack.message = message;
      this.snack.show = true;
    },
  },
};
</script>
<style scoped>
.truncate {
  max-width: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
