<template>
  <v-input v-model="matrix">
    <v-data-table
        :headers="headers"
        :items="matrix"
        disable-sort
        disable-pagination
        disable-filtering
        hide-default-footer
        dense
        class="w-100">
      <template v-slot:item.rating="{item}">
        <div class="text-no-wrap">
          <v-icon x-small :color="Math.abs(item.rating) > 0 || item.rating === 0 ? ratingColors[Math.abs(item.rating)] : 'rgba(0, 0, 0, .2)'">{{ icons.mdiCircle }}</v-icon>
          {{ ratingLabel(item) }}
        </div>
      </template>
      <template v-slot:item.requireSubs="{item}">
        <v-icon small v-if="item.requireSubs">{{ icons.mdiCheck }}</v-icon>
      </template>
      <template v-slot:item.singleChoice="{item}">
        <v-icon small v-if="item.singleChoice">{{ icons.mdiCheck }}</v-icon>
      </template>
      <template v-slot:item.answerTags="{item}">
        <v-chip-group column>
          <v-chip x-small v-for="tag of item.answerTags" :key="tag" class="my-0">{{ tag }}</v-chip>
        </v-chip-group>
      </template>
      <template v-slot:footer>
        <v-toolbar flat dense color="white">
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{on}">
              <v-btn fab x-small color="info" dark v-on="on">
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-text-field v-model.trim="editedItem.option" hide-details="auto" class="mb-4"
                                  :label="$t('option_title')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select v-model="editedItem.rating" :items="filteredRatings" item-text="label"
                              item-value="value"
                              :label="$t('rating')"
                              hide-details="auto" class="mb-4"
                    >
                      <template v-slot:append>
                        <v-icon :color="Math.abs(editedItem.rating) > 0 || editedItem.rating === 0 ? ratingColors[Math.abs(editedItem.rating)] : 'rgba(0, 0, 0, .2)'">
                          {{ icons.mdiCircle }}
                        </v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col v-if="!isSub" cols="6" sm="4">
                    <v-switch v-model="editedItem.requireSubs"
                              color="info"
                              hide-details="auto" class="mb-4"
                              :label="$t('require_subs')"></v-switch>
                  </v-col>
                  <v-col cols="6" sm="4" v-if="question.type === 'mc'">
                    <v-switch dense v-model.trim="editedItem.singleChoice"
                              hide-details="auto" class="mb-4"
                              :label="$t('single_choice')"></v-switch>
                  </v-col>
                  <v-col cols="6" sm="8">
                    <v-combobox
                        v-model="editedItem.answerTags"
                        :items="tagPool"
                        :search-input.sync="search"
                        hide-selected
                        :label="$t('answer_tags')"
                        multiple
                        deletable-chips
                        persistent-hint
                        small-chips
                        hide-details="auto" class="mb-4"
                    >
                    </v-combobox>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="secondary" text @click="close">{{ $t('button_cancel') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="info" text @click="save">{{ $t('button_save') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <span class="text-no-wrap">
            <v-btn icon x-small :disabled="Boolean(getIndex(item) === 0)" class="mr-2"
                   @click="move(true, item)">
                <v-icon small>
                    {{ icons.mdiChevronUp }}
                </v-icon>
            </v-btn>
            <v-btn icon x-small :disabled="(getIndex(item) === matrix.length - 1)" class="mr-2"
                   @click="move(false, item)">
                <v-icon small>
                    {{ icons.mdiChevronDown }}
                </v-icon>
            </v-btn>
            <v-btn icon x-small class="mr-2" @click="editItem(item)">
                <v-icon small>
                    {{ icons.mdiPencil }}
                </v-icon>
            </v-btn>
            <v-btn icon x-small @click="deleteItem(item)">
                <v-icon small>
                    {{ icons.mdiDelete }}
                </v-icon>
            </v-btn>
        </span>
      </template>
    </v-data-table>
  </v-input>
</template>

<script>
import {mdiPencil, mdiDelete, mdiPlus, mdiCheck, mdiCircle, mdiChevronUp, mdiChevronDown} from '@mdi/js';
import Short from '../shortuid';

export default {
  name: 'OptionTable',
  props: {
    question: Object,
    tagPool: Array,
    isSub: Boolean,
  },
  data: () => ({
    dialog: false,
    search: null,
    matrix: [],
    editedIndex: -1,
    editedItem: {
      id: '',
      option: '',
      rating: 0,
      requireSubs: false,
      singleChoice: false,
      answerTags: [],
    },
    defaultItem: {
      id: '',
      option: '',
      rating: 0,
      requireSubs: false,
      singleChoice: false,
      answerTags: [],
    },
    requiredRule: [
      v => !!v || 'Required',
    ],
    ratings: [
      {
        label: 'Not rated',
        value: null,
      },
      {
        label: 'Green',
        value: 0,
      },
      {
        label: 'Yellow',
        value: 1,
      },
      {
        label: 'Red',
        value: 2,
      },
      {
        label: 'Yellow (not selected)',
        value: -1,
      },
      {
        label: 'Red (not selected)',
        value: -2,
      },
    ],
    ratingColors: {
      0: 'success',
      1: 'warning',
      2: 'error',
    },
    icons: {
      mdiPencil,
      mdiDelete,
      mdiPlus,
      mdiCheck,
      mdiCircle,
      mdiChevronUp,
      mdiChevronDown,
    }
  }),
  computed: {
    filteredRatings() {
      if (this.question.type === 'mc') {
        return this.ratings;
      } else {
        return this.ratings.filter(rating => rating.value >= 0 || rating.value === null);
      }
    },
    headers() {
      let headers = [];
      if (!this.isSub) {
        headers = [
          {text: this.$t('option'), value: 'option'},
          {text: this.$t('rating'), value: 'rating'},
          {text: this.$t('sub_questions'), value: 'requireSubs', align: 'center'},
          {text: this.$t('answer_tags'), value: 'answerTags'},
          {text: this.$t('actions'), value: 'actions', align: 'right'},
        ];
      } else {
        headers = [
          {text: this.$t('option'), value: 'option'},
          {text: this.$t('rating'), value: 'rating'},
          {text: this.$t('answer_tags'), value: 'answerTags'},
          {text: this.$t('actions'), value: 'actions', align: 'right'},
        ];
      }
      if (this.question.type === 'mc') {
        headers.splice(2, 0, {
          text: this.$t('single_choice'), value: 'singleChoice', align: 'center'
        });
      }
      return headers;
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('add_option') : this.$t('edit_option');
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.matrix = [];
      if (this.question.options && this.question.options.length > 0) {
        for (const option of this.question.options) {
          let item = {
            id: option.id,
            option: option.title,
            rating: this.ratings.find(r => r.value === option.result || 0).value,
            requireSubs: option.require_subs || false,
            singleChoice: option.single_choice || false,
            answerTags: option.answer_tags || [],
          };
          this.matrix.push(item);
        }
      }
    },
    ratingLabel(item) {
      const rating = this.ratings.find(r => r.value === item.rating);
      return rating ? rating.label : '';
    },
    getIndex(item) {
      let index = -1;
      for (let idx = 0; idx < this.matrix.length; idx += 1) {
        if (item.id === this.matrix[idx].id) {
          index = idx;
        }
      }
      return index;
    },
    move(up, item) {
      let from = this.getIndex(item);
      let to = (up) ? from - 1 : from + 1;
      if (from > -1) {
        this.matrix.splice(to, 0, this.matrix.splice(from, 1)[0]);
      }
      this.storeOptions();
    },
    editItem(item) {
      this.editedIndex = this.matrix.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      const index = this.matrix.indexOf(item);
      confirm(this.$t('confirm_delete_row')) && this.matrix.splice(index, 1);
      this.storeOptions();
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (!this.editedItem.id) {
        this.editedItem.id = Short.generate();
      }
      if (this.editedIndex > -1) {
        Object.assign(this.matrix[this.editedIndex], this.editedItem);
      } else {
        this.matrix.push(this.editedItem);
      }
      this.storeOptions();
      this.close();
    },
    storeOptions() {
      this.question.options = [];
      for (const item of this.matrix) {
        this.question.options.push({
          id: item.id ? item.id : Short.generate(),
          title: item.option,
          result: item.rating,
          require_subs: item.requireSubs,
          single_choice: item.singleChoice,
          answer_tags: item.answerTags,
        });
      }
      this.$emit('changed');
    }
  },
};
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.v-chip-group .v-chip {
  margin: 0 8px 0 0;
}
</style>
