<template>
  <section class="html-editor"
           :class="{'focus': isFocus, 'content': !empty, 'long-label': labelHeight > 20, 'extra-long-label': labelHeight > 40, outlined, 'mb-8': !hideDetails}">
    <label class="mb-2 html-label" :class="{'focus': isFocus, 'content': !empty, outlined}" ref="label">{{
        label
      }}</label>

    <bubble-menu
        class="bubble-menu"
        :tippy-options="{ duration: 100 }"
        :editor="editor"
        v-if="editor"
    >
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleBold().run()"
             :color="editor.isActive('bold') ? 'info' : ''">
        <v-icon small>{{ icons.mdiFormatBold }}</v-icon>
      </v-btn>
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleItalic().run()"
             :color="editor.isActive('italic') ? 'info' : ''">
        <v-icon small>{{ icons.mdiFormatItalic }}</v-icon>
      </v-btn>
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleUnderline().run()"
             :color="editor.isActive('underline') ? 'info' : ''">
        <v-icon small>{{ icons.mdiFormatUnderline }}</v-icon>
      </v-btn>
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
             :color="editor.isActive('heading', { level: 1 }) ? 'info': ''">
        <v-icon small>{{ icons.mdiFormatHeader1 }}</v-icon>
      </v-btn>
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
             :color="editor.isActive('heading', { level: 2 }) ? 'info': ''">
        <v-icon small>{{ icons.mdiFormatHeader2 }}</v-icon>
      </v-btn>
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
             :color="editor.isActive('heading', { level: 3 }) ? 'info': ''">
        <v-icon small>{{ icons.mdiFormatHeader3 }}</v-icon>
      </v-btn>
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
             :color="editor.isActive('heading', { level: 4 }) ? 'info': ''">
        <v-icon small>{{ icons.mdiFormatHeader4 }}</v-icon>
      </v-btn>
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleBulletList().run()"
             :color="editor.isActive('bulletList') ? 'info': ''">
        <v-icon small>{{ icons.mdiFormatListBulleted }}</v-icon>
      </v-btn>
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleOrderedList().run()"
             :color="editor.isActive('orderedList') ? 'info': ''">
        <v-icon small>{{ icons.mdiFormatListNumbered }}</v-icon>
      </v-btn>
    </bubble-menu>

    <floating-menu
        class="floating-menu"
        :tippy-options="{ duration: 100 }"
        :editor="editor"
        v-if="editor"
    >
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleBold().run()"
             :color="editor.isActive('bold') ? 'info' : ''">
        <v-icon small>{{ icons.mdiFormatBold }}</v-icon>
      </v-btn>
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleItalic().run()"
             :color="editor.isActive('italic') ? 'info' : ''">
        <v-icon small>{{ icons.mdiFormatItalic }}</v-icon>
      </v-btn>
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleUnderline().run()"
             :color="editor.isActive('underline') ? 'info' : ''">
        <v-icon small>{{ icons.mdiFormatUnderline }}</v-icon>
      </v-btn>
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
             :color="editor.isActive('heading', { level: 1 }) ? 'info': ''">
        <v-icon small>{{ icons.mdiFormatHeader1 }}</v-icon>
      </v-btn>
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
             :color="editor.isActive('heading', { level: 2 }) ? 'info': ''">
        <v-icon small>{{ icons.mdiFormatHeader2 }}</v-icon>
      </v-btn>
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
             :color="editor.isActive('heading', { level: 3 }) ? 'info': ''">
        <v-icon small>{{ icons.mdiFormatHeader3 }}</v-icon>
      </v-btn>
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
             :color="editor.isActive('heading', { level: 4 }) ? 'info': ''">
        <v-icon small>{{ icons.mdiFormatHeader4 }}</v-icon>
      </v-btn>
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleBulletList().run()"
             :color="editor.isActive('bulletList') ? 'info': ''">
        <v-icon small>{{ icons.mdiFormatListBulleted }}</v-icon>
      </v-btn>
      <v-btn x-small icon class="mr-1" @click="editor.chain().focus().toggleOrderedList().run()"
             :color="editor.isActive('orderedList') ? 'info': ''">
        <v-icon small>{{ icons.mdiFormatListNumbered }}</v-icon>
      </v-btn>
    </floating-menu>
    <editor-content :editor="editor"/>
  </section>
</template>

<script>
import {
  Editor,
  EditorContent,
  BubbleMenu,
  FloatingMenu
} from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import {
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatHeader1,
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatHeader4,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiFormatUnderline,
} from '@mdi/js';

export default {
  name: 'HtmlEditor',
  components: {
    EditorContent,
    BubbleMenu,
    FloatingMenu
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    editor: null,
    isFocus: false,
    changed: false,
    labelHeight: 10,
    icons: {
      mdiFormatBold,
      mdiFormatItalic,
      mdiFormatHeader1,
      mdiFormatHeader2,
      mdiFormatHeader3,
      mdiFormatHeader4,
      mdiFormatListBulleted,
      mdiFormatListNumbered,
      mdiFormatUnderline
    },
  }),
  watch: {
    value(value) {
      console.log(value);
      const isSame = this.editor.getHTML() === value;
      if (isSame) {
        return;
      }
      this.editor.commands.setContent(this.value, false);
    },
  },
  computed: {
    empty() {
      return !this.value || this.value === '<p></p>';
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Underline,
        Link.configure({}),
      ],
      editable: !this.readonly,
      content: this.value,
      onUpdate: () => {
        this.html = this.editor.getHTML();
        setTimeout(() => this.$emit('input', this.editor.getHTML()), 100);
      },
      onFocus: () => {
        this.isFocus = true;
      },
      onBlur: () => {
        this.isFocus = false;
      }
    });
    this.labelHeight = this.$refs.label.offsetHeight;
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {}
};
</script>

<style lang="scss">

@keyframes label-kf {
  0% {
    top: 16px;
  }
  100% {
    top: -8px;
  }
}

@keyframes label-kf-outlined {
  0% {
    top: 14px;
  }
  100% {
    left: 8px;
    top: -10px;
  }
}

.html-editor {
  border-bottom: thin solid rgba(0, 0, 0, .45);
  position: relative;
  padding-top: 14px;
  padding-bottom: 2px;
}

.html-editor.outlined {
  border: thin solid rgba(0, 0, 0, .45);
  border-radius: 4px;
  padding: 12px 12px 16px 12px;
}

.html-editor.focus:not(.outlined) {
  border-bottom: 2px solid var(--v-primary-base);
}

.html-editor.outlined.focus {
  border: 2px solid var(--v-primary-base);
}

.html-editor.long-label {
  //padding-top: 40px;
}

.html-editor.extra-long-label {
  //padding-top: 60px;
}

.html-label {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  position: absolute;
  top: 12px;
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.html-label.outlined {
  display: inline-block;
  background-color: white;
  top: 14px;
}

.html-label.content {
  font-size: 75%;
  top: -8px;
}

.html-label.content.outlined {
  left: 8px;
}

.html-label.focus, .html-label.content {
  font-size: 75%;
  animation-name: label-kf;
  animation-duration: 300ms;
  top: -8px;
}

.html-label.focus.outlined, .html-label.content.outlined {
  top: -10px;
  left: 8px;
  background-color: white;
  animation-name: label-kf-outlined;
  padding: 0 2px;
}

.html-label.focus {
  color: var(--v-primary-base);
}

.bubble-menu {
  display: flex;
  background-color: rgba(255, 255, 255, .9);
  padding: 0.2rem;
  border: thin solid rgba(0, 0, 0, .075);
  border-radius: 0.5rem;
}

.floating-menu {
  display: flex;
  background-color: rgba(255, 255, 255, .9);
  padding: 0.2rem;
  border: thin solid rgba(0, 0, 0, .075);
  border-radius: 0.5rem;
}

.ProseMirror.ProseMirror-focused {
  outline: none;
}

.ProseMirror {
  color: rgba(0, 0, 0, 0.87);
  //padding: 0 0 4px;
  //margin-bottom: 8px;

  > * + * {
    margin-top: .5rem;
  }

  p {
    margin-bottom: .5rem;
  }

  p:last-child {
    margin-bottom: 0;
  }

  strong {
    font-weight: 600;
  }

  ul,
  ol {
    padding: 0 2rem;
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 600;
    line-height: 1.1;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.3rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  h4 {
    font-size: 1rem;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
  }
}

.html-editor.outlined .ProseMirror {
  border: none;
  padding: 0;
  margin-bottom: 0;
}
</style>
